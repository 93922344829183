import style from "./Select.module.scss";
import React from "react";

interface SelectProps {
  label?: string;
  options: Array<{option: string; id?: number; disabled?: boolean}>;
  value?: number;
  onChange: Function;
}

export const Select: React.FunctionComponent<SelectProps> = (props) => {
  const handleChange = (e: any) => {
    props.onChange(e);
  };

  return (
    <div className={"columnFlex"}>
      <div className={style.label}>{props.label && <span className={"label"}>{props.label}</span>}</div>
      {props.options && (
        <select className={[style["custom-select"], style.select].join(" ")} aria-label="" value={props.value || ""} onChange={handleChange}>
          {props.options.map((e, index) => {
            return (
              <option key={index} value={e.id}>
                {e.option}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
};
