import {OverrideAmountOption} from "@common/typing";

export const aboutCancellationStaticData = {
    requesters: [
        {
            option: "Select Requester",
            id: null,
            reasons: [{option: "Select Reason", id: null, id_requester: 1, requiredNote: false}],
        },
        {
            option: "Vacasa",
            id: 1,
            reasons: [
                {option: "Select Reason", id: null, id_requester: 1, requiredNote: false},
                {option: "Natural Disaster", id: 1, id_requester: 1, requiredNote: false},
                {option: "Unit Out of Order", id: 2, id_requester: 1, requiredNote: false},
                {
                    option: "Forced Move (Release Unused Hold)",
                    id: 3,
                    id_requester: 1,
                    requiredNote: false,
                },
                {option: "Test", id: 19, id_requester: 1, requiredNote: false},
                {option: "IDV Failure", id: 20, id_requester: 1, requiredNote: false},
                {option: "Block List", id: 21, id_requester: 1, requiredNote: false},
                {option: "Unpaid", id: 22, id_requester: 1, requiredNote: false},
            ],
        },
        {
            option: "Owner",
            id: 2,
            reasons: [
                {option: "Select Reason", id: null, id_requester: 2, requiredNote: false},
                {
                    option: "Forced Move (Cancel & Refund)",
                    id: 4,
                    id_requester: 2,
                    requiredNote: false,
                },
                {
                    option: "Forced Move (Moved to New Unit)",
                    id: 5,
                    id_requester: 2,
                    requiredNote: false,
                },
                {option: "Owner Cancellation", id: 6, id_requester: 2, requiredNote: false},
            ],
        },
        {
            option: "Guest",
            id: 3,
            reasons: [
                {option: "Select Reason", id: null, requiredNote: false},
                {
                    option: "Card Decline (Guest Disagreed to Payment Terms)",
                    id: 7,
                    id_requester: 3,
                    requiredNote: false,
                },
                {
                    option: "Card Decline (Guest Unresponsive)",
                    id: 8,
                    id_requester: 3,
                    requiredNote: false,
                },
                {option: "Dates Have Changed", id: 9, id_requester: 3, requiredNote: false},
                {option: "Extenuating Circumstance", id: 10, id_requester: 3, requiredNote: false},
                {option: "House Rules", id: 11, id_requester: 3, requiredNote: false},
                {option: "Insufficient Funds", id: 12, id_requester: 3, requiredNote: false},
                {option: "Made in Error", id: 13, id_requester: 3, requiredNote: false},
                {option: "Natural Disaster", id: 14, id_requester: 3, requiredNote: false},
                {option: "No Longer Need Accomodation", id: 15, id_requester: 3, requiredNote: false},
                {option: "Rebooked Directly", id: 16, id_requester: 3, requiredNote: false},
                {option: "Within Grace Period", id: 17, id_requester: 3, requiredNote: false},
                {option: "Other", id: 18, id_requester: 3, requiredNote: true},
            ],
        },
    ],
};

export const overrideAmountOptions: OverrideAmountOption[] = [
    {id: 1, option: "Total paid minus booking fee", amount: 0, allowCustomRefund: false},
    {id: 2, option: "Total paid minus booking fee and minus TP", amount: 0, allowCustomRefund: false},
    {id: 3, option: "Custom refund", amount: 0, allowCustomRefund: true},
];
