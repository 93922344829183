import React, {useState} from "react";
import cx from "classnames";
import styles from "./Dropzone.module.scss";
import {BsDownload, BsTrash} from "react-icons/bs";

interface DropzoneViewProps {
  title: string;
  selectedFile: FileDetail;
  onChange: Function;
}

interface FileDetail {
  name: string;
  type: string;
  fileBuffer: any;
}

/**
 * Allows you to see the detail of the attachments
 * @param DropzoneViewProps
 * @returns component
 */
export const DropzoneView: React.FC<DropzoneViewProps> = ({title, selectedFile, onChange}) => {
  const handleDownload = () => {
    const bytes = new Uint8Array(selectedFile.fileBuffer);
    const file = new Blob([bytes], {type: selectedFile.type});
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(file);
    downloadLink.download = selectedFile.name;
    downloadLink.click();
  };

  const deleteFile = (e: any) => {
    onChange(e);
  };

  return (
    <div style={{position: "relative"}} className={cx("box", "columnFlex")}>
      <div className={styles.titleAttachment}>
        <span className="boxTitle">{title}</span>
      </div>
      <div className={styles.attachmentsView}>
        <span>File Name</span>
      </div>
      {selectedFile && (
        <div className={styles.attachmentsDetail}>
          <span>{selectedFile.name}</span>
          <div className={styles.iconsContainer}>
            <button className={styles.iconButton} onClick={handleDownload}>
              <BsDownload />
            </button>
            <button className={styles.iconButton} onClick={deleteFile}>
              <BsTrash />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
