import {FrontendConfiguration} from "@common/configuration";
import {CancelNotificationPayload} from "@common/typing";
import * as Sentry from "@sentry/react";
import {Checkbox, Image, Modal} from "@vacasa/react-components-lib";
import React, {useState} from "react";
import {AlertComponent, Button, EmailContainer, ErrorSendingEmail} from "../../components";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import styles from "./Email.module.scss";
import {sendEmailCancellation, setEmailError} from "../../store/cancellationWorkflowSlice";
import {LegacyUrlGenerator} from "@common/utils";
import {redirectToURL} from "../../utils";

export const Email: React.FunctionComponent<any> = ({data}) => {
  const dispatch = useAppDispatch();
  const reservation = useSelector((state: RootState) => state.cancellationWorkflow.reservation);
  const currencyCode = useSelector((state: RootState) => state.finance?.payment?.currencyCode || "USD");
  const totalAmountPaid = useSelector((state: RootState) => state.finance.payment.totalAmountPaid);
  const totalCancellationCharges = useSelector((state: RootState) => state.finance.adjustmentPreview.totalCancellationCharges);
  const recommendedRefund = useSelector((state: RootState) => state.finance.adjustmentPreview.recommendedRefund);
  const creditCards = useSelector((state: RootState) => state.finance.payment.creditCardsParsed);
  const finalRefund = useSelector((state: RootState) => state.finance.adjustmentPreview.finalRefund);
  const email = useSelector((state: RootState) => state.cancellationWorkflow.email);
  const emailLoading = useSelector((state: RootState) => state.cancellationWorkflow.email.action.loading);

  const cancellationCharges = useSelector((state: RootState) => state.finance.adjustmentPreview?.data?.attributes?.preview_finances);
  const fees = cancellationCharges?.fees;
  const taxes = cancellationCharges?.taxes;
  const rent = cancellationCharges?.rent;

  const {url} = FrontendConfiguration.adminConfig();
  const initialState = JSON.parse(localStorage.getItem("skipModal")) || false;
  const [showAlert, setShowAlert] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(initialState);
  let reservationAdminUrl = `${url}/admin/dashboard/reservation/${reservation?.data?.attributes?.legacy_reservation_id}`;

  const refundData = {
    totalAmountPaid,
    totalCancellationCharges,
    currencyCode,
    recommendedRefund,
    finalRefund,
    creditCards,
    fees,
    rent,
    taxes,
  };

  const checkSkipModal = () => {
    const modalIsChecked = JSON.parse(localStorage.getItem("skipModal"));
    if (modalIsChecked) {
      redirectToURL(LegacyUrlGenerator.toReservation(reservation?.data?.attributes?.legacy_reservation_id));
    } else {
      setShowModal((prev) => !prev);
    }
  };

  const handleInputChange = () => {
    localStorage.setItem("skipModal", JSON.stringify(!checked));
    setChecked(JSON.parse(localStorage.getItem("skipModal")));
  };

  const handleClick = async () => {
    try {
      if (email?.data?.recipientsEmails.length === 0) {
        dispatch(setEmailError("You must add recipients to send the email"));
      } else if (email?.modal?.error) {
        dispatch(setEmailError("Some recipient has an invalid format. You must correct it before sending the email"));
      } else {
        const payload: CancelNotificationPayload = {
          to: email?.data?.recipientsEmails.map((r) => ({
            address: r,
            name: `${reservation?.data?.attributes?.guestInformation?.first_name} ${reservation?.data?.attributes?.guestInformation?.last_name}`,
            type: "to",
          })),
          note: email?.data?.bodyInformation,
          ...refundData,
        };

        await dispatch(sendEmailCancellation({reservationId: reservation?.data?.id, payload})).unwrap();
        redirectToURL(LegacyUrlGenerator.toReservation(reservation?.data?.attributes?.legacy_reservation_id));
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <Modal setShowModal={setShowModal} showModal={showModal} size="medium">
        <div className={styles.modalContent}>
          <h3 className={styles.h3}>
            You're <span className={styles.textDanger}>not</span> sending a confirmation email to the Guest
          </h3>
          <p>Guests rely heavily on receiving this email for their peace of mind. Not sending it can cause them unnecessary frustration.</p>
          <Checkbox type="default" label="Don't ask me again" checked={checked} onChange={handleInputChange} disabled={false} />

          <Button label="Write confirmation email" onClick={() => setShowModal((prev) => !prev)} />
          <a href={reservationAdminUrl} target="_top">
            <p className={styles.toRegretText}>Skip sending email</p>
          </a>
        </div>
      </Modal>
      <div className={styles.mainContainer}>
        {showAlert && (
          <div className={styles.alertContainer}>
            <AlertComponent text="Reservation cancelled and refund sent." className="success" iconSize="25" setShowAlert={setShowAlert} />
          </div>
        )}
        <div className={styles.box}>
          <h2 className={"boxTitle"}>Send confirmation email</h2>
          <p>This reservation is being cancelled. Send the Guest a confirmation of their cancellation.</p>

          <EmailContainer data={data} title="Email Preview" />
        </div>

        <div className={styles.btnBox}>
          <Button label="Skip" mode="primaryOutlined" onClick={checkSkipModal} />
          <Button label="Send Email" mode="primary" onClick={handleClick} />
        </div>
      </div>
      <Modal showModal={emailLoading} setShowModal={() => {}} size={"small"} canExit={false}>
        <div className={styles.sendingMessage}>
          <Image.Spinner style={{display: "flex"}} width={50} />
          <span className={styles.message}>Sending email...</span>
        </div>
      </Modal>
      <ErrorSendingEmail />
    </>
  );
};
