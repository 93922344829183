import React, {useEffect, useState, useCallback} from "react";
import {toLocalString} from "../../utils";
import cx from "classnames";
import styles from "./AmountInfo.module.scss";
export interface AmountInfoProps {
  amount: string | number;
  prefix: string;
  editable: boolean;
  maxAmount: number;
  type?: string;
  onChange?: Function;
  onClick?: Function;
  maxButton?: boolean;
}

export const AmountInfo: React.FunctionComponent<AmountInfoProps> = ({amount, prefix, maxAmount, type, editable, onChange, onClick}) => {
  const [amountValue, setAmountValue] = useState(amount);
  const [amountString, setAmountString] = useState("");
  const [errorMode, setErrorMode] = useState(false);

  useEffect(() => {
    setAmountString(toLocalString(Number(amount), prefix));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  useEffect(() => {
    setAmountString(toLocalString(Number(amount), prefix));
    if (Number(amountValue) > maxAmount) {
      setErrorMode(true);
    } else {
      setErrorMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountValue, prefix, maxAmount]);

  const getValue = useCallback(() => {
    return amountValue;
  }, [amountValue]);

  useEffect(() => {
    if (onChange) onChange(getValue());
  }, [amountValue, onChange, getValue]);

  return (
    <div className={cx(styles.amountInfoBox, "columnFlex")}>
      {editable ? (
        <div className={cx("columnFlex")}>
          <p>Amount to refund</p>
          <input
            className={cx(styles.amountSection, styles.input, errorMode ? styles.errorBox : "")}
            type={"text"}
            value={amountString}
            onChange={(e) => {
              setAmountValue(toLocalString(Number(e.currentTarget.value), prefix));
            }}
          />
          <span className={cx(styles.fontSmall, errorMode ? styles.errorText : null)}>{`Can't exceed ${toLocalString(maxAmount, prefix)}`}</span>
        </div>
      ) : (
        <div
          className={cx("rowReverseFlex", styles.amountSection, type === "detail" ? styles.amountText : styles.textNotEditable)}
          onClick={() => {
            if (onClick) onClick();
          }}
        >
          {amountString}
        </div>
      )}
    </div>
  );
};
