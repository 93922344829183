import styles from "./BankCardAmount.module.scss";

import React from "react";

interface BankCardAmountProps {
  left: React.ReactNode;
  right: React.ReactNode;
  editable: boolean;
}

export const BankCardAmount: React.FunctionComponent<BankCardAmountProps> = (props) => {
  return (
    <div className={props.editable ? styles.splitPanelEditable : styles.splitPanel}>
      {props.left}

      {props.right}
    </div>
  );
};
