import {toLocalString} from "../Functions/functions";

export const cancelPreviewParser = (data: any) => {
    return [
        {
            type: "refund",
            data: [
                {
                    type: "columns",
                    elements: [{text: "Original Cost"}, {text: toLocalString(Number(data?.originalCost), data?.currencyCode)}],
                },
                {
                    type: "columns",
                    elements: [{text: "Amount paid"}, {text: toLocalString(Number(data?.totalAmountPaid), data?.currencyCode)}],
                },
                {
                    type: "separator",
                },
                {
                    type: "columns",
                    elements: [
                        {text: "Cancellation charges"},
                        {
                            text: `-${toLocalString(Number(data?.totalCancellationCharges), data?.currencyCode)}`,
                        },
                    ],
                },
                {
                    type: "columns",
                    elements: [
                        {text: "Taxes", classes: "fontSoft fontSmall"},
                        {
                            text: `-${toLocalString(data?.totalTaxes, data?.currencyCode)}`,
                            classes: "fontSoft fontSmall",
                        },
                    ],
                },
                {
                    type: "columns",
                    elements: [
                        {text: "Fees", classes: "fontSoft fontSmall"},
                        {
                            text: `-${toLocalString(data?.totalFees, data?.currencyCode)}`,
                            classes: "fontSoft fontSmall",
                        },
                    ],
                },
                {
                    type: "columns",
                    elements: [
                        {text: "Rents", classes: "fontSoft fontSmall"},
                        {
                            text: `-${toLocalString(data?.totalRents, data?.currencyCode)}`,
                            classes: "fontSoft fontSmall",
                        },
                    ],
                },
                {
                    type: "separator",
                },
                {
                    type: "columns",
                    elements: [
                        {
                            text: "Recommended Refund",
                            classes: "fontNormalBold",
                            info: "This is based off the [channel] refund cancellation policy. This amount can be overriden with good cause.",
                        },
                        {
                            text: toLocalString(Number(data?.recommendedRefund), data?.currencyCode),
                            classes: "total fontLarge",
                        },
                    ],
                },
            ],
        },
    ];
};

/**
 * Generates the content that will show refund cancellation, it is simplified for Airbnb
 * @param data
 * @returns array
 */
export const cancelPreviewParserMinify = (data: any) => {
    return [
        {
            type: "refund",
            data: [
                {
                    type: "columns",
                    elements: [{text: "Original Cost"}, {text: toLocalString(Number(data?.originalCost), data?.currencyCode)}],
                },
                {
                    type: "columns",
                    elements: [{text: "Amount paid"}, {text: toLocalString(Number(data?.totalAmountPaid), data?.currencyCode)}],
                },
                {
                    type: "separator",
                },
                {
                    type: "columns",
                    elements: [
                        {
                            text: "Recommended Refund",
                            classes: "fontNormalBold",
                            info: "This is based off the [channel] refund cancellation policy. This amount can be overriden with good cause.",
                        },
                        {
                            text: toLocalString(Number(data?.recommendedRefund), data?.currencyCode),
                            classes: "total fontLarge",
                        },
                    ],
                },
            ],
        },
    ];
};
