import {AdjustmentPreviewFee, AdjustmentPreviewRent, AdjustmentPreviewTaxes, Fee, Rent, Tax} from "@common/typing";
import {BOOKING_FEE_NAME, TRIP_PROTECTION_FEE_NAME} from "../../constants";

//Filter the fees to only include the booking fee and trip protection fee (if has trip protection)
export const getEligibleFees = (fees: AdjustmentPreviewFee[], reservationHasTripProtection: boolean): AdjustmentPreviewFee[] =>
    fees?.filter((fee: Fee) => {
        if (reservationHasTripProtection) {
            return fee.name === BOOKING_FEE_NAME || fee.name === TRIP_PROTECTION_FEE_NAME;
        } else {
            return fee.name === BOOKING_FEE_NAME;
        }
    });

//Taxes amount is zeroed
export const getOverrideTaxes = (previewTaxes: AdjustmentPreviewTaxes[]): AdjustmentPreviewTaxes[] =>
    previewTaxes?.map((tax: Tax) => ({
        ...tax,
        id: tax.id,
        amount: "0",
    }));

//Rent amount is zeroed
export const getOverrideRent = (previewRents: AdjustmentPreviewRent[]): AdjustmentPreviewRent[] =>
    previewRents?.map((rent: Rent) => ({
        ...rent,
        rent: "0",
        date: rent.date,
    }));
