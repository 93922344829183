import React from "react";
import cx from "classnames";
import styles from "./BankCardInfo.module.scss";
export interface BankCardInfoProps {
  img: string;
  lastDigits: string;
  description: string;
}

export const BankCardInfo: React.FunctionComponent<BankCardInfoProps> = (props) => {
  return (
    <div className={cx(styles.bankCardInfo, "rowFlex")}>
      <div className={cx(styles.logoBox, "flex")}>
        <img className={styles.logo} src={props.img} alt={"logo"} width={41} />
      </div>
      <div className={cx("columnFlex", styles.infoBox)}>
        <span className={cx(styles.font, styles.fontNormal)}>
          {`\u2217\u2217\u2217\u2217 
                        \u2217\u2217\u2217\u2217 
                        \u2217\u2217\u2217\u2217 
                        ${props.lastDigits}`}
        </span>
        <span className={cx(styles.font, styles.fontSmall)}>{props.description}</span>
      </div>
    </div>
  );
};
