import styles from "./VerticalTimeline.module.scss";

import React, {useState} from "react";
import cx from "classnames";
import _ from "lodash";
import {makeStyles} from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";

interface VerticalTimelineProps {
  events: Array<{
    active: boolean;
    title: string;
    lines?: Array<{
      label?: string;
      desc: string;
      link?: {
        url: string;
        desc: string;
      } | null;
    }>;
    date_desc?: string;
  }>;
}

const useStyles = makeStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none",
    },
  },
});

export const VerticalTimeline: React.FunctionComponent<VerticalTimelineProps> = (props) => {
  const initialActive = () => {
    const activeIndex =
      props.events &&
      props.events
        .map((e) => {
          return !!e.active;
        })
        .indexOf(true);
    return activeIndex !== -1 ? activeIndex : props?.events?.length;
  };

  const [active] = useState(initialActive());
  const [clicked, setClicked] = useState(initialActive());
  const classes = useStyles();

  return (
    <Timeline
      classes={{
        root: styles.scheduleBox,
      }}
    >
      {props.events &&
        props?.events?.map((event, index) => {
          return (
            <TimelineItem
              key={index}
              classes={{
                missingOppositeContent: classes.missingOppositeContent,
                root: styles.eventRow,
              }}
            >
              <TimelineSeparator
                classes={{
                  root: styles.timelineSection,
                }}
              >
                <div className={index === active ? styles.verticalBarTopBoxActive : styles.verticalBarTopBox}>
                  <TimelineConnector
                    classes={{
                      root:
                        index === 0
                          ? styles.hidden
                          : index <= active
                          ? cx(styles.verticalBar, styles.verticalBarTop, styles.pastVerticalBar)
                          : cx(styles.verticalBar, styles.verticalBarTop),
                    }}
                  />
                </div>
                <TimelineDot
                  classes={{
                    root: cx(styles.dot, event.active ? styles.activeDot : index < active ? styles.pastDot : styles.passiveDot),
                  }}
                />
                <TimelineConnector
                  classes={{
                    root:
                      index === props?.events?.length - 1
                        ? styles.hidden
                        : index < active
                        ? cx(styles.verticalBar, styles.pastVerticalBar)
                        : styles.verticalBar,
                  }}
                />
              </TimelineSeparator>
              <TimelineContent
                classes={{
                  root: styles.timelineContent,
                }}
              >
                <div
                  className={
                    index < active
                      ? cx(styles.clickable, "fontBodyRegularDefault")
                      : index === clicked
                      ? "fontBodyBoldDefault"
                      : cx(styles.clickable, "fontFieldLabel")
                  }
                  onClick={() => setClicked(index)}
                >
                  {event.title}
                </div>
                {!_.isEmpty(event.lines) && (
                  <div className={cx(index !== clicked ? (event.title !== "Same day booking" ? styles.hidden : null) : null, styles.eventRowLines)}>
                    {event.lines?.map((l, index) => {
                      return (
                        <div className={styles.labelContainer} key={index}>
                          {l.label && <span className={cx("fontBodyBoldSmall", styles.lineLabel)}>{l.label}</span>}
                          <span className={"fontBodyRegularSmall"}>{l.desc}</span>
                          {!_.isNil(l.link) && (
                            <a className={cx("fontBodySemiBoldSmall", styles.link)} href={l.link.url} target={"_blank"} rel="noopener noreferrer">
                              {l.link.desc}
                            </a>
                          )}
                        </div>
                      );
                    })}
                    {event.date_desc ? <div className={cx("fontBodyRegularTiny", styles.dateDesc)}>{event.date_desc}</div> : null}
                  </div>
                )}
                {index !== clicked ? <div className={styles.eventSeparator}></div> : null}
              </TimelineContent>
            </TimelineItem>
          );
        })}
    </Timeline>
  );
};
