import {NewRefundAmount} from "@common/typing";
import {CUSTOM_REFUND} from "../../constants";
import {Reason} from "src/typing";
import {isNaN, isNil} from "lodash";
import {AIRBNB} from "../../constants";

//Override Amounts Validations [Override reason, New refund amount, Custom refund, Authorize by & Notes]
export const isValidReason = (reasonSelected: string) => {
    return reasonSelected !== "" && reasonSelected !== "0";
};

export const isValidRefundOptionSelected = (newRefundAmountOption: string) => {
    return newRefundAmountOption !== "" && !!newRefundAmountOption;
};

export const isValidAuthorization = (approvedBy: string) => {
    return approvedBy !== "" && !!approvedBy && approvedBy.includes("@vacasa.com");
};

export const isValidCustomRefund = (newRefundAmountOption: NewRefundAmount) => {
    if (!newRefundAmountOption.allowCustomRefund) return true;
    return newRefundAmountOption.allowCustomRefund && newRefundAmountOption.refundAmount.replace(/[^0-9.]+/g, "") !== "";
};

export const isValidCustomTripProtection = (customTpValue: string, reservationHasTripProtection: boolean, optionSelected: string) => {
    return parseInt(optionSelected) === CUSTOM_REFUND ? (reservationHasTripProtection ? customTpValue.replace(/[^0-9.]+/g, "") !== "" : true) : true;
};

export const isValidNote = (reasonSelected: boolean, note: string) => {
    if (!reasonSelected) return true;
    return reasonSelected && note !== "";
};

//About Cancellation Validations [Requested By, Cancellation Reason & Internal cancellation notes]
export const isValidRequester = (requestedBy: number) => {
    return !isNil(requestedBy) && !isNaN(requestedBy);
};

export const isValidCancellationReason = (reason: Reason) => {
    return !isNil(reason?.id) && !isNaN(reason?.id);
};

export const isValidInternalNote = (requiredNote: boolean, internalCancellationNotes: string) => {
    if (!requiredNote) return true;
    return requiredNote && !!internalCancellationNotes.trim();
};

/**
 * Validates that if it is an airbnb reservation and cancellationStatus is true, the debouncedExplanation field must be required
 * @param boolean isAirbnbChannel
 * @param boolean cancellationStatus
 * @param string debouncedExplanation
 * @returns boolean
 */
export const isValidExplanationAirbnb = (isAirbnbChannel: boolean, cancellationStatus: boolean, debouncedExplanation: string): boolean => {
    if (!isAirbnbChannel || !cancellationStatus) return true;
    return cancellationStatus && !!debouncedExplanation.trim();
};

/**
 * Proceed to verify if the form is filled out correctly
 * @param number requester requested by
 * @param Reason reason  Cancellation Reason
 * @param string internalNote Internal cancellation notes
 * @param boolean isAirbnbChannel channel to which the reservation corresponds
 * @param boolean cancellationStatus if it was canceled on airbnb) yes or no)
 * @param string debouncedExplanation  Explanation to AirBnB
 * @returns boolean
 */
export const validateRequesterFilled = (
    requester: number,
    reason: Reason,
    internalNote: string,
    isAirbnbChannel: boolean,
    cancellationStatus: boolean,
    debouncedExplanation: string
): boolean => {
    return (
        isValidRequester(requester) &&
        isValidCancellationReason(reason) &&
        isValidInternalNote(reason?.requiredNote, internalNote) &&
        isValidExplanationAirbnb(isAirbnbChannel, cancellationStatus, debouncedExplanation)
    );
};
