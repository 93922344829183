import React from "react";
import styles from "./BackSection.module.scss";
import {BsArrowLeftShort} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {redirectToURL} from "../../utils";
import {LegacyUrlGenerator} from "@common/utils";

type Props = {
  reservationId: string;
};

export const BackSection = ({reservationId}: Props) => {
  const navigate = useNavigate();
  const currentPath = useSelector((state: RootState) => state.cancellationWorkflow.currentPathname);

  const handleClickRedirect = () => {
    navigate(-1);
  };

  const handleClickAdminRedirect = () => {
    redirectToURL(LegacyUrlGenerator.toReservation(reservationId));
  };

  return (
    <>
      {(currentPath === `/${reservationId}` || currentPath === `/${reservationId}/review`) && (
        <span
          style={{cursor: "pointer", width: "11%"}}
          onClick={currentPath === `/${reservationId}` ? handleClickAdminRedirect : handleClickRedirect}
        >
          <BsArrowLeftShort style={{verticalAlign: "middle"}} size={"1.5rem"} />
          Back
        </span>
      )}
      <h1 className={styles.title}>Cancel Reservation</h1>
    </>
  );
};
