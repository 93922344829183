import React from "react";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import {Details, Header, Review, Email} from "./views";
import cx from "classnames";
import "./index.scss";

const App = (): JSX.Element => {
  return (
    <div className={cx("columnFlex", "innerMargin")}>
      <div>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Navigate to="/undefined" />} />
            <Route path="/:id" element={<Details />} />
            <Route path="/:id/email" element={<Email />} />
            <Route path="/:id/review" element={<Review />} />
            <Route path="*" element={<Navigate to={"/"} />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
};

export default App;
