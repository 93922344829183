import React from "react";
import ContentLoader from "react-content-loader";

export const ReservationDetailsLoader = () => (
  <ContentLoader viewBox="0 0 400 100" height={100} width={400}>
    <rect x="0" y="10" rx="6" ry="6" width={60} height="15" />
    <rect x="80" y="10" rx="6" ry="6" width={200} height="15" />
    <rect x="0" y="40" rx="6" ry="6" width={60} height="15" />
    <rect x="80" y="40" rx="6" ry="6" width={100} height="15" />
  </ContentLoader>
);

export const AboutCancellationLoader = () => (
  <ContentLoader viewBox="0 0 600 400" height={400} width={600}>
    <rect x="0" y="10" rx="6" ry="6" width={275} height="20" />
    <rect x="0" y="55" rx="6" ry="6" width={100} height="15" />
    <rect x="0" y="80" rx="6" ry="6" width={200} height="35" />
    <rect x="0" y="150" rx="6" ry="6" width={150} height="15" />
    <rect x="0" y="185" rx="6" ry="6" width={570} height="35" />
    <rect x="0" y="250" rx="6" ry="6" width={250} height="15" />
    <rect x="0" y="285" rx="6" ry="6" width={570} height="110" />
  </ContentLoader>
);

export const RefundDetailsLoader = () => (
  <ContentLoader viewBox="0 0 600 170" height={170} width={600}>
    <rect x="0" y="10" rx="6" ry="6" width={175} height="20" />
    <rect x="320" y="10" rx="6" ry="6" width={250} height="15" />

    <rect x="0" y="65" rx="6" ry="6" width={570} height="40" />

    <rect x="0" y="140" rx="6" ry="6" width={210} height="30" />
    <rect x="490" y="140" rx="6" ry="6" width={80} height="25" />
  </ContentLoader>
);

export const RefundSummaryLoader = () => (
  <ContentLoader viewBox="0 0 600 300" height={300} width={600}>
    <rect x="0" y="10" rx="6" ry="6" width={175} height="20" />
    <rect x="0" y="55" rx="6" ry="6" width={100} height="15" />
    <rect x="200" y="55" rx="6" ry="6" width={100} height="15" />
    <rect x="0" y="95" rx="6" ry="6" width={100} height="15" />
    <rect x="200" y="95" rx="6" ry="6" width={100} height="15" />
    <rect x="0" y="130" rx="6" ry="6" width={300} height="2" />
    <rect x="0" y="150" rx="6" ry="6" width={100} height="15" />
    <rect x="200" y="150" rx="6" ry="6" width={100} height="15" />
    <rect x="0" y="180" rx="6" ry="6" width={70} height="15" />
    <rect x="200" y="180" rx="6" ry="6" width={100} height="15" />
    <rect x="0" y="210" rx="6" ry="6" width={70} height="15" />
    <rect x="200" y="210" rx="6" ry="6" width={100} height="15" />
    <rect x="0" y="240" rx="6" ry="6" width={70} height="15" />
    <rect x="200" y="240" rx="6" ry="6" width={100} height="15" />
    <rect x="0" y="275" rx="6" ry="6" width={300} height="2" />
    <rect x="0" y="285" rx="6" ry="6" width={70} height="15" />
    <rect x="200" y="285" rx="6" ry="6" width={100} height="15" />
  </ContentLoader>
);

export const CancellationScheduleLoader = () => (
  <ContentLoader viewBox="0 0 600 300" height={300} width={600}>
    <rect x="0" y="10" rx="6" ry="6" width={175} height="20" />
    <rect x="0" y="55" rx="6" ry="6" width={100} height="15" />
    <rect x="200" y="55" rx="6" ry="6" width={100} height="15" />
    <rect x="0" y="95" rx="6" ry="6" width={100} height="15" />
    <rect x="200" y="95" rx="6" ry="6" width={100} height="15" />
    <rect x="0" y="130" rx="6" ry="6" width={300} height="2" />
    <rect x="0" y="150" rx="6" ry="6" width={100} height="15" />
    <rect x="200" y="150" rx="6" ry="6" width={100} height="15" />
    <rect x="0" y="180" rx="6" ry="6" width={70} height="15" />
    <rect x="200" y="180" rx="6" ry="6" width={100} height="15" />
    <rect x="0" y="210" rx="6" ry="6" width={70} height="15" />
    <rect x="200" y="210" rx="6" ry="6" width={100} height="15" />
    <rect x="0" y="240" rx="6" ry="6" width={70} height="15" />
    <rect x="200" y="240" rx="6" ry="6" width={100} height="15" />
    <rect x="0" y="275" rx="6" ry="6" width={300} height="2" />
    <rect x="0" y="285" rx="6" ry="6" width={70} height="15" />
    <rect x="200" y="285" rx="6" ry="6" width={100} height="15" />
  </ContentLoader>
);
