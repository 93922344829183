import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EventService, ResManService, ReservationImplicitService} from "@common/services";
import {isEmpty} from "lodash";
import {OverrideReason, ReservationCancelledItem, ReservationIncludedType, ReservationItem, UnitItemAttributes} from "@common/typing";
import {OverrideData, AboutCancellationData, TripProtection} from "../typing";
import {calculateDaysUntilCheckin, parseAdjustmentsToEvents} from "../utils";
import * as Sentry from "@sentry/react";
import {datadogLogs} from "@datadog/browser-logs";
import {EventSources, EventTypes} from "@common/utils";
import {AIRBNB} from "../constants";

// Service singletons
const resManService = ResManService.getInstance();
const reservationService = ReservationImplicitService.getInstance();

export const getReservationById = createAsyncThunk(
    "cancellationWorkflowSlice/getReservationById",
    async (id: string, {rejectWithValue}: any): Promise<ReservationItem> => {
        const params = ["guests", "source", "addons", "cancellation_schedule"];
        try {
            const reservation = await reservationService.getReservation(id, false);
            if (isEmpty(reservation?.data)) {
                return rejectWithValue({
                    error: "Reservation not found. Please make sure that the reservation ID exists.",
                });
            }
            return await resManService.getReservationById(reservation?.data[0]?.id, params);
        } catch (error) {
            return rejectWithValue({
                error: "Error fetching reservation. Please reload the page or try again later. If this message persists, please contact support.",
            });
        }
    }
);

export const getUnitById = createAsyncThunk(
    "cancellationWorkflowSlice/getUnitById",
    async (id: string, {rejectWithValue}: any): Promise<UnitItemAttributes> => {
        try {
            return await resManService.getUnitById(id);
        } catch (error) {
            return rejectWithValue({
                error: "Error fetching the unit. Please reload the page or try again later. If this message persists, please contact support.",
            });
        }
    }
);

export const getOverrideReasons = createAsyncThunk(
    "cancellationWorkflowSlice/getOverrideReasons",
    async (_, {rejectWithValue}: any): Promise<OverrideReason[]> => {
        const filters = {
            "filter[adjustments.type]": "cancel_reservation",
        };
        try {
            const overrideReason = await resManService.getOverrideReasons(filters);
            //We return only the active reasons
            return overrideReason?.filter((reason: OverrideReason) => reason?.attributes?.is_active);
        } catch (error) {
            return rejectWithValue({
                error: "Error fetching the override reasons. Please reload the page or try again later. If this message persists, please contact support.",
            });
        }
    }
);

export const cancelReservation = createAsyncThunk(
    "cancellationWorkflowSlice/cancelReservation",
    async (data: any): Promise<ReservationCancelledItem> => {
        const {reservationId, cancellationObject} = data;
        try {
            const response = await resManService.cancelAndRefundReservation(reservationId, cancellationObject);
            //Dispatch event to datadog when a cancellation is successful
            EventService.dispatch(datadogLogs, {
                title: "Cancellation Success",
                message: `Reservation UUID: ${reservationId}`,
                type: EventTypes.CANCELLATION_WORKFLOW_CANCEL_SUCCESS,
                source: EventSources.UI,
                level: EventService.INFO_LEVEL,
                data: {cancellationObject},
            });
            return response;
        } catch (error) {
            const errorDetails = error?.data?.detail?.message || error?.data?.detail?.error?.name || "unexpected error";
            Sentry.captureException(new Error(error));
            //Dispatch event to datadog when a cancellation fails
            EventService.dispatch(datadogLogs, {
                title: "Cancellation Fail",
                message: `Reservation UUID: ${reservationId}`,
                type: EventTypes.CANCELLATION_WORKFLOW_CANCEL_FAIL,
                source: EventSources.UI,
                level: EventService.ERROR_LEVEL,
                data: {cancellationObject, response: errorDetails},
            });
            throw new Error(`Error while cancelling the reservation. Details: ${errorDetails}`);
        }
    }
);

export const cancelOverrideReservation = createAsyncThunk(
    "cancellationWorkflowSlice/cancelOverrideReservation",
    async (data: any): Promise<ReservationCancelledItem> => {
        const {reservationId, cancellationOverrideObject} = data;
        try {
            const response = await resManService.cancelOverrideReservation(reservationId, cancellationOverrideObject);
            //Dispatch event to datadog when a override cancellation is successful
            EventService.dispatch(datadogLogs, {
                title: "Override Cancellation Success",
                message: `Reservation UUID: ${reservationId}`,
                type: EventTypes.CANCELLATION_WORKFLOW_OVERRIDE_CANCEL_SUCCESS,
                source: EventSources.UI,
                level: EventService.INFO_LEVEL,
                data: {cancellationOverrideObject},
            });
            return response;
        } catch (error) {
            const errorDetails = error?.data?.detail?.message || "";
            Sentry.captureException(new Error(error));
            //Dispatch event to datadog when a override cancellation fails
            EventService.dispatch(datadogLogs, {
                title: "Override Cancellation Fail",
                message: `Reservation UUID: ${reservationId}`,
                type: EventTypes.CANCELLATION_WORKFLOW_OVERRIDE_CANCEL_FAIL,
                source: EventSources.UI,
                level: EventService.ERROR_LEVEL,
                data: {cancellationOverrideObject, response: errorDetails},
            });
            throw new Error(`Error while cancelling the override reservation. Details: ${errorDetails}`);
        }
    }
);

export const sendEmailCancellation = createAsyncThunk("cancellationWorkflowSlice/sendEmailCancellation", async (data: any): Promise<any> => {
    const {reservationId, payload} = data;
    try {
        return await resManService.sendEmailCancellation(reservationId, payload);
    } catch (error) {
        const errorDetails = error?.data?.detail?.message || error?.data?.detail?.error?.name || "unexpected error";
        throw new Error(`Error sending the cancellation email reservation. Details: ${errorDetails}`);
    }
});
export interface CancellationWorkflowState {
    reservation: {
        data: ReservationItem;
        included: ReservationIncludedType[];
        hasTripProtection: boolean;
        modal: {
            error: boolean;
            errorMessage: string;
            warningCancellation: boolean;
        };
        action: {
            fetching: boolean;
            loading: boolean;
            cancelling: boolean;
        };
    };
    cancellationSchedule: {
        attributes: any;
        checkInDate: string;
        tripProtection: TripProtection;
        daysUntilCheckIn: number;
        scheduleParsedEvents: any; //TODO: Add type
    };
    unit: {
        data: UnitItemAttributes;
        action: {
            fetching: boolean;
        };
    };
    aboutCancellation: AboutCancellationData;
    override: OverrideData;
    email: {
        data: {
            recipientsEmails: string[];
            bodyInformation: string;
        };
        action: {
            loading: boolean;
        };
        modal: {
            error: boolean;
            errorMessage: string;
        };
    };
    currentPathname: string;
}

const initialState: CancellationWorkflowState = {
    reservation: {
        data: null,
        included: null,
        hasTripProtection: false,
        modal: {
            error: false,
            errorMessage: "",
            warningCancellation: false,
        },
        action: {
            fetching: true,
            loading: false,
            cancelling: false,
        },
    },
    cancellationSchedule: {
        attributes: null,
        checkInDate: null,
        tripProtection: null,
        daysUntilCheckIn: null,
        scheduleParsedEvents: null,
    },
    unit: {
        data: null,
        action: {
            fetching: true,
        },
    },
    aboutCancellation: {
        requestedBy: null,
        reason: {
            id: null,
            requiredNote: false,
        },
        internalNotes: "",
        explanationNotes: "",
        cancelOnAirbnb: false,
        file: null,
        isValid: false,
    },
    override: {
        reason: {
            id: null,
            name: null,
            requiredNote: false,
        },
        refund: {
            refundAmount: null,
            refundAmountOption: null,
            allowCustomRefund: false,
            tripProtectionAmount: null,
        },
        authorizeBy: null,
        notes: "",
        checked: false,
        isValid: false,
        overrideReasons: null,
    },
    email: {
        data: {
            recipientsEmails: null,
            bodyInformation: "",
        },
        action: {
            loading: false,
        },
        modal: {
            error: false,
            errorMessage: null,
        },
    },
    currentPathname: null,
};

const cancellationWorkflowSlice = createSlice({
    name: "cancellationWorkflowSlice",
    initialState,
    reducers: {
        displayReservationModalError: (state) => {
            state.reservation.modal.error = true;
        },
        setReservationModalErrorMessage: (state, {payload}) => {
            state.reservation.modal.errorMessage = payload;
        },
        setAboutCancellationData: (state, {payload}) => {
            state.aboutCancellation = payload;
        },
        setOverrideInfo: (state, {payload}) => {
            state.override = payload;
        },
        setToggleCancellation: (state, {payload}) => {
            state.override.checked = payload;
        },
        setValidCancellation: (state, {payload}) => {
            state.aboutCancellation.isValid = payload;
        },
        hideWarningCancellationModal: (state) => {
            state.reservation.modal.warningCancellation = false;
        },
        displayWarningCancellationModal: (state) => {
            state.reservation.modal.warningCancellation = true;
        },
        setAmountValidationError: (state, {payload}) => {
            state.reservation.modal.error = true;
            state.reservation.modal.errorMessage = `Recommended Refund $${payload?.recommendedRefund} is more than amount paid $${payload.totalAmountPaid}.`;
        },
        setEmailData: (state, {payload}) => {
            state.email.data = payload;
        },
        setEmailError: (state, {payload}) => {
            state.email.modal.error = true;
            state.email.modal.errorMessage = payload;
        },
        hideEmailModalError: (state) => {
            state.email.modal.error = false;
            state.email.modal.errorMessage = null;
        },
        setOverrideSectionValid: (state, {payload}) => {
            state.override.isValid = payload;
        },
        setCurrentPathname: (state, {payload}) => {
            state.currentPathname = payload;
        },
        resetCustomRefundOption: (state) => {
            state.override.refund = initialState.override.refund;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReservationById.pending, (state) => {
                state.reservation.action.fetching = true;
            })
            .addCase(getReservationById.fulfilled, (state, {payload}) => {
                const addonsInclude = payload?.included?.filter((includeItem: any) => includeItem.type === "addon")[0];
                const cancellationScheduleInclude = payload?.included?.filter((includeItem: any) => includeItem.type === "cancellation_schedule")[0];
                state.reservation.action.fetching = false;
                state.reservation.data = payload;
                state.reservation.data.channelName = payload?.included?.find((item) => item.type === "source")?.attributes?.name;
                state.reservation.data.isAirbnbChannel = state.reservation.data.channelName.toUpperCase() === AIRBNB ? true : false;
                state.reservation.included = payload?.included;
                state.reservation.hasTripProtection =
                    payload?.included[0]?.attributes.name === "trip_protection" && payload?.included[0]?.attributes.meta.status === "yes";
                state.cancellationSchedule.attributes = cancellationScheduleInclude?.attributes;
                state.cancellationSchedule.checkInDate = payload?.attributes?.first_night;
                state.cancellationSchedule.daysUntilCheckIn = calculateDaysUntilCheckin(payload?.attributes?.first_night);
                state.cancellationSchedule.tripProtection = addonsInclude?.attributes?.meta?.trip_protection_provider
                    ? {
                          type: addonsInclude?.attributes?.name,
                          desc: addonsInclude?.attributes?.meta?.trip_protection_provider.replace("_", " ").replace(/\b\w/g, (c) => c.toUpperCase()),
                          provider: addonsInclude?.attributes?.meta?.trip_protection_provider,
                          ...(addonsInclude?.attributes?.meta?.trip_protection_fee_id && {
                              id: addonsInclude?.attributes?.meta?.trip_protection_fee_id,
                          }),
                      }
                    : null;
                state.cancellationSchedule.scheduleParsedEvents = parseAdjustmentsToEvents(
                    state.cancellationSchedule?.attributes?.schedule,
                    state.cancellationSchedule.tripProtection,
                    cancellationScheduleInclude?.attributes?.desc_codes
                );
            })
            .addCase(getReservationById.rejected, (state, {payload}: any) => {
                state.reservation.action.fetching = false;
                state.reservation.modal.error = true;
                state.reservation.modal.errorMessage = payload?.error || payload;
            })
            .addCase(getOverrideReasons.fulfilled, (state, {payload}) => {
                state.override.overrideReasons = payload;
            })
            .addCase(getUnitById.pending, (state) => {
                state.unit.action.fetching = true;
            })
            .addCase(getUnitById.fulfilled, (state, {payload}) => {
                state.unit.action.fetching = false;
                state.unit.data = payload;
            })
            .addCase(getUnitById.rejected, (state, {payload}: any) => {
                state.reservation.modal.error = true;
                state.reservation.modal.errorMessage = payload?.error?.message;
            })
            .addCase(cancelReservation.pending, (state) => {
                state.reservation.action.cancelling = true;
            })
            .addCase(cancelReservation.fulfilled, (state) => {
                state.reservation.action.cancelling = false;
            })
            .addCase(cancelReservation.rejected, (state, payload) => {
                state.reservation.action.cancelling = false;
                state.reservation.modal.error = true;
                state.reservation.modal.errorMessage = payload?.error?.message;
            })
            .addCase(cancelOverrideReservation.pending, (state) => {
                state.reservation.action.cancelling = true;
            })
            .addCase(cancelOverrideReservation.fulfilled, (state) => {
                state.reservation.action.cancelling = false;
            })
            .addCase(cancelOverrideReservation.rejected, (state, payload) => {
                state.reservation.action.cancelling = false;
                state.reservation.modal.error = true;
                state.reservation.modal.errorMessage = payload?.error?.message;
            })
            .addCase(sendEmailCancellation.pending, (state) => {
                state.email.action.loading = true;
            })
            .addCase(sendEmailCancellation.fulfilled, (state) => {
                state.email.action.loading = false;
            })
            .addCase(sendEmailCancellation.rejected, (state, payload) => {
                state.email.action.loading = false;
                state.email.modal.error = true;
                state.email.modal.errorMessage = payload?.error?.message;
            });
    },
});

export const {
    displayReservationModalError,
    setAboutCancellationData,
    setOverrideInfo,
    setToggleCancellation,
    setValidCancellation,
    hideWarningCancellationModal,
    displayWarningCancellationModal,
    setAmountValidationError,
    setEmailData,
    setEmailError,
    hideEmailModalError,
    setOverrideSectionValid,
    setReservationModalErrorMessage,
    setCurrentPathname,
    resetCustomRefundOption,
} = cancellationWorkflowSlice.actions;

export default cancellationWorkflowSlice.reducer;
