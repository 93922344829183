import React, {useEffect, useState} from "react";
import {Button, Checkbox} from "@vacasa/react-components-lib";
import {CancellationSchedule} from "../../components/cancellation-schedule/CancellationSchedule";
import {Dropzone} from "../../components/inputs/dropzone/Dropzone";
import {CreditCard, RefundSummary, OverrideAmounts, AboutCancellation, RefundDetailsLoader, ReservationErrorModal} from "../../components/";
import cx from "classnames";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import styles from "./Details.module.scss";
import {RootState, useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {setCurrentPathname, setToggleCancellation} from "../../store/cancellationWorkflowSlice";
import {CANCELLATION_SCHEDULE_TITLE, REFUND_SUMMARY_TITLE} from "../../constants";
import {setAboutCancellationData} from "../../store/cancellationWorkflowSlice";

export const Details = () => {
  //Redux State
  const dispatch = useAppDispatch();
  const reservation = useSelector((state: RootState) => state.cancellationWorkflow?.reservation);
  const aboutCancellationData = useSelector((state: RootState) => state.cancellationWorkflow.aboutCancellation);
  const isAirbnbChannel = reservation?.data?.isAirbnbChannel;
  const cancellationSchedule = useSelector((state: RootState) => state.cancellationWorkflow.cancellationSchedule);
  const isOverrideChecked = useSelector((state: RootState) => state.cancellationWorkflow.override.checked);
  const isValidOverride = useSelector((state: RootState) => state.cancellationWorkflow.override.isValid);
  const isValidCancellation = useSelector((state: RootState) => state.cancellationWorkflow.aboutCancellation.isValid);
  const [selectedFile, setSelectedFile] = useState(aboutCancellationData.file);

  const fetchingData = useSelector(
    (state: RootState) =>
      state.cancellationWorkflow.reservation.action.fetching ||
      state.cancellationWorkflow.unit.action.fetching ||
      state.finance.adjustmentPreview.action.fetching ||
      state.finance.payment.action.fetching
  );

  const navigate = useNavigate();

  //State section
  const [overrideChecked, setOverrideChecked] = useState<boolean>(isOverrideChecked || false);
  const {pathname} = useLocation();
  const {id: reservationId} = useParams<{id: string}>();

  useEffect(() => {
    dispatch(setCurrentPathname(pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextStep = () => {
    navigate(`/${reservationId}/review`);
  };

  const handleClickCheckbox = () => {
    setOverrideChecked(!overrideChecked);
    dispatch(setToggleCancellation(!overrideChecked));
  };

  useEffect(() => {
    if (selectedFile) {
      dispatch(
        setAboutCancellationData({
          ...aboutCancellationData,
          file: selectedFile,
        })
      );
    }
  }, [selectedFile]);

  return (
    <>
      <div className={"flex"}>
        <div className={"columnFlex"}>
          <div className={"box"}>
            <AboutCancellation title="About the Cancellation" />
          </div>

          {!isAirbnbChannel && (
            <div style={{position: "relative"}} className={cx("box", "columnFlex")}>
              {fetchingData ? (
                <RefundDetailsLoader />
              ) : (
                <>
                  <div className={styles.titleRefundDetails}>
                    <span className="boxTitle">Refund Details</span>
                    <span>
                      <Checkbox
                        label="Override policy refund amount"
                        checked={overrideChecked}
                        onChange={handleClickCheckbox}
                        type="default"
                        disabled={!isValidCancellation}
                      />
                    </span>
                  </div>
                  {overrideChecked && <OverrideAmounts />}
                  <CreditCard />
                </>
              )}
            </div>
          )}
          {isAirbnbChannel && aboutCancellationData.cancelOnAirbnb === true && (
            <Dropzone title="Attachments" setFile={setSelectedFile} selectedFile={selectedFile} />
          )}
          <div className={styles.btnBox}>
            <Button onClick={nextStep} variant={"secondary"} disabled={!isValidCancellation || (isOverrideChecked && !isValidOverride)}>
              Review Details
            </Button>
          </div>
        </div>
        <div className={`${styles.rightPanel} columnFlex`}>
          {/* Refund Summary Component */}
          <RefundSummary title={REFUND_SUMMARY_TITLE} />

          {/* Cancellation Schedule Component */}
          <div className={"innerMarginBox"}>
            <CancellationSchedule title={CANCELLATION_SCHEDULE_TITLE} schedule={cancellationSchedule} />
          </div>
        </div>
      </div>
      <ReservationErrorModal />
    </>
  );
};
