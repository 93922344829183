import {FrontendConfiguration} from "@common/configuration";
import moment from "moment-timezone";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import {ALLOWED_LABELS} from "../../constants";
import {getTripProtectionProviderName} from "../Functions/functions";

export const parseAdjustmentsToEvents = (adjustments: any, tripProtection: any, descriptionCodes: string[]): Array<any> => {
    // Get the index of the first active event
    const initialActive = () => {
        const activeIndex = adjustments
            ?.filter((scheduleEvent) => {
                return !isNil(scheduleEvent.eligibility.start) && !isNil(scheduleEvent.eligibility.end);
            })
            .map((scheduleEvent) => {
                return !!scheduleEvent.active;
            })
            .indexOf(true);
        return activeIndex !== -1 ? activeIndex : adjustments?.length;
    };

    // Return the schedule events to be displayed
    const parsedEvents = adjustments
        ?.filter((scheduleEvent) => {
            return !isNil(scheduleEvent.eligibility.start) && !isNil(scheduleEvent.eligibility.end);
        })
        ?.map((scheduleEvent, index) => {
            let lines = [];
            ALLOWED_LABELS.forEach((allowedLabel) => {
                lines.push({
                    label: allowedLabel.label,
                    desc:
                        !isNil(tripProtection) && allowedLabel.type === "future_stay_credit"
                            ? `Not eligible with ${tripProtection.id ? getTripProtectionProviderName(tripProtection?.id) : tripProtection?.desc}`
                            : isEmpty(scheduleEvent.repayment) || isNil(scheduleEvent.repayment[allowedLabel.type])
                            ? "Not eligible"
                            : !isEmpty(scheduleEvent.repayment) && !isNil(scheduleEvent.repayment[allowedLabel.type])
                            ? scheduleEvent.repayment[allowedLabel.type].desc
                            : "",
                    link:
                        isNil(tripProtection) && allowedLabel.type === "future_stay_credit" && descriptionCodes?.includes("FSC_SAME_UNIT_ONLY")
                            ? {
                                  url: FrontendConfiguration.getSameUnitOnlyLink(),
                                  desc: "Same unit only.",
                              }
                            : null,
                });
            });

            let date_desc = getCheckinDateFormatted(adjustments.check_in_date, scheduleEvent);
            let title = scheduleEvent.eligibility.desc;
            let active = scheduleEvent.active;

            if (index < initialActive())
                title += ` (ended ${moment.tz(scheduleEvent.eligibility.end, FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD")})`;
            else if (scheduleEvent.repayment?.refund?.desc === "100%" && scheduleEvent.repayment?.future_stay_credit?.desc === "100%")
                title += ` | Full refund`;
            else if (isEmpty(scheduleEvent.repayment?.refund) && isEmpty(scheduleEvent.repayment?.future_stay_credit)) title += ` | No refund`;

            if (descriptionCodes?.includes("SAME_DAY_BOOKING")) {
                const checkinMinusCurrentDate = moment(adjustments.check_in_date).diff(
                    moment().tz(FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD"),
                    "days"
                );
                active = checkinMinusCurrentDate === 0;
                title = "Same day booking";
                lines = [{label: "No Refund", desc: "for same day bookings", link: null}];
            }

            return {
                active,
                title,
                lines,
                date_desc,
            };
        });

    return parsedEvents;
};
export const getCheckinDateFormatted = (checkingDate: string, event: any): string => {
    let date_desc = "";
    if (!isNil(checkingDate) && checkingDate === moment.tz(event.eligibility.end, FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD")) {
        date_desc = `Checking-in ${moment.tz(event.eligibility.end, FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD")}`;
    } else if (event.active) {
        date_desc = moment.tz(event.eligibility.end, FrontendConfiguration.getTimeZone()).format("[Ends] h:mma z [on] YYYY-MM-DD");
    } else {
        date_desc = `From ${
            !isNil(event.eligibility.start) ? moment.tz(event.eligibility.start, FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD") : null
        }\xa0\xa0to\xa0\xa0${
            !isNil(event.eligibility.end) ? moment.tz(event.eligibility.end, FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD") : null
        }`;
    }
    return date_desc;
};

export const calculateDaysUntilCheckin = (checkinDate: string): number => {
    let daysUntilCheckin = null;
    if (!isNil(checkinDate)) {
        daysUntilCheckin = moment(checkinDate).diff(moment().tz(FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD"), "days");
        if (daysUntilCheckin < 0) {
            daysUntilCheckin = 0;
        }
    }
    return daysUntilCheckin;
};
