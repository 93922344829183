import React from "react";
import styles from "./TextArea.module.scss";
import {Help} from "../help/Help";

interface TextAreaProps {
  title: string;
  placeholder?: string;
  rows?: number;
  disabled?: boolean;
  value?: string;
  onChange?: Function;
  delayOnChange?: number;
  info?: string;
}

export const TextArea: React.FunctionComponent<TextAreaProps> = ({value, title, disabled, placeholder, rows, delayOnChange, onChange, info}) => {
  const handleChange = (e: any) => {
    onChange(e);
  };

  return (
    <div className={"columnFlex"}>
      {title && (
        <span className={styles.title}>
          {title} {info && <Help info={info} />}
        </span>
      )}

      {disabled ? (
        <textarea className={styles.textarea} defaultValue={value} placeholder={placeholder} rows={rows || 3} disabled />
      ) : (
        <textarea className={styles.textarea} defaultValue={value} placeholder={placeholder} rows={rows || 3} onKeyUp={handleChange} />
      )}
    </div>
  );
};
