import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {FrontendConfiguration} from "@common/configuration";
import {ImplicitFlow, JwtValidator, ThemeProvider} from "@vacasa/react-components-lib";
import DefaultTheme from "@vacasa/react-components-lib/src/themes/default";
import {datadogLogs, LogsEvent} from "@datadog/browser-logs";
import {obfuscateTokenFromUrl} from "@common/utils";
import {Provider} from "react-redux";
import {store} from "./store/store";
import App from "./App";

const params = new URLSearchParams(window.location.search);

let token: string = params.get("token");
const adminConfig = FrontendConfiguration.adminConfig();
const environment = FrontendConfiguration.environment();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

Sentry.init({
  dsn: FrontendConfiguration.getSentryDsn(),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.01,
  environment,
});

// configure logger
if (environment !== "local") {
  datadogLogs.init({
    clientToken: FrontendConfiguration.getDataDogToken(),
    site: FrontendConfiguration.dataDogUrl(),
    forwardErrorsToLogs: true,
    sampleRate: 100,
    beforeSend: (log: LogsEvent) => {
      log.view.url = obfuscateTokenFromUrl(log.view.url);
    },
  });
  datadogLogs.setLoggerGlobalContext(FrontendConfiguration.datadogCustomGlobalContext(FrontendConfiguration.DD_RESERVATION_MANAGEMENT_UI));
}

// if loaded, we remove the reference to force refreshed cache
sessionStorage.removeItem("cache-force-refreshed");

root.render(
  <React.StrictMode>
    <ThemeProvider theme={DefaultTheme}>
      <JwtValidator jwt={token} base64Key={adminConfig.publicKey} storageName={FrontendConfiguration.tokenStorageName}>
        <ImplicitFlow {...FrontendConfiguration.implicitFlowConfig()} followRedirect={true}>
          <Provider store={store}>
            <App />
          </Provider>
        </ImplicitFlow>
      </JwtValidator>
    </ThemeProvider>
  </React.StrictMode>
);
