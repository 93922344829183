//Cancellation
export const MANUAL_OVERRIDE_TYPE: string = "MANUAL_OVERRIDE";
export const CANCEL_RESERVATION_TYPE: string = "cancel_reservation";

//Override
export const FSC: string = "FSC";
export const FORCED_MOVE: string = "FORCED_MOVE";
export const BOOKING_FEE_NAME: string = "Booking Fee";
export const TRIP_PROTECTION_FEE_NAME: string = "TripProtectionFee";
export const TOTAL_PAID_MINUS_BOOKING_FEE: number = 1;
export const TOTAL_PAID_MINUS_BOOKING_FEE_AND_TP: number = 2;
export const CUSTOM_REFUND: number = 3;
export const OTHER_REASON = "OTHER";

//Titles
export const REFUND_TITLE: string = "Refund";
export const CANCELLATION_TITLE: string = "Cancellation";
export const REFUND_SUMMARY_TITLE: string = "Refund Summary";
export const CANCELLATION_SCHEDULE_TITLE: string = "Cancellation Schedule";

//Finances
export const CREDIT_CARD: string = "CREDIT_CARD";
export const VISA: string = "VISA";
export const MASTERCARD: string = "MASTERCARD";
export const MC: string = "MC";

//Types channels reservation
export const AIRBNB: string = "AIRBNB";

export enum TripProtectionProviders {
    RED_SKY = "5",
    PLAY_TRAVEL_PROTECTION_STD = "1350",
    PLAY_TRAVEL_PROTECTION_CFAR = "1351",
}

// Trip Protection Providers Info
export const TRIP_PROTECTION_PROVIDERS_INFO = {
    red_sky: {
        name: "Red Sky",
        phone: "1-855-292-5908",
        link: "https://www.trippreserverclaims.com",
    },
    // This property name won't be updated to "play_travel_protection" because we need to match it with the Reservation Service response
    rental_guardian: {
        name: "Play Travel Protection",
        phone: "1-833-610-0736",
        link: "https://www.playtravelprotection.com/start-a-claim",
    },
};

// Trip Protection Providers
export const TRIP_PROTECTION_PROVIDERS = {
    [TripProtectionProviders.RED_SKY]: "Red Sky",
    [TripProtectionProviders.PLAY_TRAVEL_PROTECTION_STD]: "Play Travel Protection STD",
    [TripProtectionProviders.PLAY_TRAVEL_PROTECTION_CFAR]: "Play Travel Protection CFAR",
};

// Allowed Labels to be displayed in the Cancellation Schedule
export const ALLOWED_LABELS = [
    {
        type: "refund",
        label: "Refund",
    },
    {
        type: "future_stay_credit",
        label: "FSC",
    },
];
