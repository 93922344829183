import React from "react";
import styles from "./JustifiedTable.module.scss";
import {FiInfo, FiX} from "react-icons/fi";

interface JustifiedTableProps {
  rows: Array<{
    type: string;
    elements?: Array<{
      text: string;
      classes?: string;
      info?: string;
    }>;
  }>;
}

export const JustifiedTable: React.FunctionComponent<JustifiedTableProps> = (props) => {
  return (
    <div className={styles.justifyTable}>
      {props &&
        props?.rows?.map((row, index) => {
          return (
            <div
              key={index}
              className={row.type === "separator" ? styles.separator : styles.justifyRow}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {row.type !== "separator" &&
                row.elements.map((e, i) => {
                  let classList = [];
                  if (e.classes) {
                    e.classes.split(" ").forEach((c) => {
                      if (styles[c]) {
                        classList.push(styles[c]);
                      }
                    });
                  }

                  return (
                    <div style={{display: "flex", alignItems: "center"}} key={i} className={classList.length > 0 ? classList.join(" ") : null}>
                      {e.text}
                      {e.info ? (
                        <div
                          onMouseEnter={(event) => {
                            event.currentTarget.getElementsByClassName(styles.tooltipContentHidden)[0].classList.add(styles.tooltipContentVisible);
                          }}
                          className={styles.tooltipWrapper}
                        >
                          <FiInfo style={{marginLeft: "5px"}} />
                          <div className={styles.tooltipContentHidden}>
                            <div style={{alignSelf: "flex-start"}}>
                              <FiInfo size={22} />
                            </div>
                            <div style={{margin: "0 10px"}}>{e.info}</div>
                            <div
                              style={{
                                alignSelf: "start",
                                cursor: "pointer",
                              }}
                              onClick={(event) => {
                                event.currentTarget.parentElement.classList.remove(styles.tooltipContentVisible);
                              }}
                            >
                              <FiX size={22} style={{alignSelf: "center"}} />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
            </div>
          );
        })}
    </div>
  );
};
