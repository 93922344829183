import React from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {Button, Icon, Modal, Checkbox} from "@vacasa/react-components-lib";
import {FrontendConfiguration} from "@common/configuration";
import styles from "./Modals.module.scss";
import {getTripProtectionProviderInfo, redirectToURL} from "../../utils";
import {LegacyUrlGenerator} from "@common/utils";
import {hideEmailModalError, hideWarningCancellationModal} from "../../store/cancellationWorkflowSlice";
import {EarlyCheckin} from "../../assets/images";
import {BsExclamationTriangle} from "react-icons/bs";

export const ReservationErrorModal = () => {
  const reservation = useSelector((state: RootState) => state.cancellationWorkflow.reservation);
  const reservationError = useSelector(
    (state: RootState) => state.finance.adjustmentPreview.modal.error || state.cancellationWorkflow.reservation.modal.error
  );
  const reservationErrorMessage = useSelector(
    (state: RootState) => state.finance.adjustmentPreview.modal.errorMessage || state.cancellationWorkflow.reservation.modal.errorMessage
  );
  const {url} = FrontendConfiguration.adminConfig();

  return (
    <Modal setShowModal={() => {}} showModal={reservationError} size="medium" canExit={false}>
      <div className={styles.modalContent}>
        <div className="flexCenterHorizontalEnd">
          <h3 className={styles.titleMessage}>Reservation Error</h3>
          <span className={styles.trashIconError}>
            <Icon.AlertTriangle width={40} height={40} />
          </span>
        </div>
        <p className={styles.descriptionMessage}>{reservationErrorMessage}</p>
        <p className={styles.descriptionMessageOld}>
          If the message detail above is related to an already cancelled reservation error and you believe that this reservation is really not
          canceled, then you can use
          <a
            href={`${url}/admin/dashboard/reservation/finance/beginAdjustment?reservationId=${reservation?.data?.attributes?.legacy_reservation_id}&adjustmentType=cancelReservation&no-redirect`}
            target="_top"
          >
            &nbsp;this link
          </a>{" "}
          to cancel with the old method.
        </p>
        <div className={styles.buttonsContainer}>
          <Button
            customClass={styles.redirectButton}
            variant="secondary"
            onClick={() => redirectToURL(LegacyUrlGenerator.toReservation(reservation?.data?.attributes?.legacy_reservation_id))}
          >
            Go to reservation
          </Button>
          <Button customClass={styles.reloadButton} variant="secondary" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const CancelReservationMessageModal = ({onClick, checked, onChange}) => {
  const dispatch = useAppDispatch();
  const reservation = useSelector((state: RootState) => state.cancellationWorkflow.reservation);
  const warningCancellation = useSelector((state: RootState) => state.cancellationWorkflow.reservation.modal.warningCancellation);
  const tripProtection = useSelector((state: RootState) => state.cancellationWorkflow.cancellationSchedule.tripProtection);
  const tripProtectionInformation = getTripProtectionProviderInfo(tripProtection?.provider);

  const TripProtectionLink = ({link}): JSX.Element => (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {link}
    </a>
  );
  const TripProtectionPhone = ({phone}) => <a href={`tel:${phone}`}>{phone}</a>;

  const TripProtectionEmailMessage = ({providerName, link, phone}) => {
    return (
      <>
        The guest secured travel protections through our travel insurance provider <span className={styles.tpProvider}>{providerName}</span>. They can
        file a claim to <span className={styles.tpProvider}>{providerName}</span> for any reservation costs that have not been refunded. For the guest
        to file a claim with <span className={styles.tpProvider}>{providerName}</span>, they must go to {link} to start their trip cancellation claim
        online. For questions regarding the coverage of the policy or questions on how to file a claim, the guest can call{" "}
        <span className={styles.tpProvider}>{providerName}'s</span> customer service at {phone} for assistance.
      </>
    );
  };

  return (
    <Modal setShowModal={() => dispatch(hideWarningCancellationModal())} showModal={warningCancellation} size="medium">
      <div className={styles.modalContent}>
        <img src={EarlyCheckin} className={styles.imgBody} alt="imgBody" />
        <h3 className={styles.h3}>Are you sure you want to cancel?</h3>
        <p>
          This will not be able to be undone if you continue. <br />
          {reservation?.hasTripProtection ? (
            <TripProtectionEmailMessage
              providerName={tripProtectionInformation.name}
              link={<TripProtectionLink link={tripProtectionInformation.link} />}
              phone={<TripProtectionPhone phone={tripProtectionInformation.phone} />}
            />
          ) : null}
        </p>

        <div className={styles.checkNotAsk}>
          <Checkbox type="default" label="Don't ask me again" checked={checked} onChange={onChange} disabled={false} />
        </div>
        <Button variant="secondary" onClick={onClick}>
          Process Cancellation
        </Button>
        <p className={styles.toRegretText} onClick={() => dispatch(hideWarningCancellationModal())}>
          I’ve changed my mind
        </p>
      </div>
    </Modal>
  );
};

export const ErrorSendingEmail = () => {
  const dispatch = useAppDispatch();
  const emailError = useSelector((state: RootState) => state.cancellationWorkflow.email.modal.error);
  const emailErrorMessage = useSelector((state: RootState) => state.cancellationWorkflow.email.modal.errorMessage);
  return (
    <Modal setShowModal={() => dispatch(hideEmailModalError())} showModal={emailError} size="small">
      <div className={styles.modalContent}>
        <h3 className={styles.titleMessage}>Error Sending the Email</h3>
        <p className={styles.descriptionMessage}>{emailErrorMessage}</p>
      </div>
    </Modal>
  );
};

/**
 * Shows the warning dialog about the cancellation process
 * @returns component
 */
export const WarningCancellationAirbnb = () => {
  return (
    <div className={styles.warningDiv}>
      <div className={styles.warningMessage}>
        <div className={styles.warningCircle}>
          <BsExclamationTriangle className={styles.warningIcon} />
        </div>
        <span className={styles.message}>Vacasa will be charged $1000.00 if this cancellation waiver request is not approved.</span>
      </div>
    </div>
  );
};
