import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EventService, ResManService} from "@common/services";
import {
    AdjustmentPreviewPayload,
    AdjustmentPreviewResponse,
    AdjustmentPreviewResponseData,
    AdjustmentPreviewResponseFinances,
    CreditCardParsed,
    PaymentSummaryItem,
    PaymentsSummaryItem,
} from "@common/typing";
import {CREDIT_CARD} from "../constants";
import {creditCardsParser} from "../utils";
import {datadogLogs} from "@datadog/browser-logs";
import {EventSources, EventTypes} from "@common/utils";

// Service singletons
const resManService = ResManService.getInstance();

export const getReservationPaymentSummary = createAsyncThunk(
    "finance/getReservationPaymentSummary",
    async (id: string): Promise<PaymentsSummaryItem> => {
        try {
            return await resManService.getReservationPaymentSummary(id);
        } catch (error) {
            throw new Error(
                "Error fetching the reservation payment summary. Please reload the page or try again later. If this message persists, please contact support."
            );
        }
    }
);

export const createAdjustmentPreview = createAsyncThunk("finance/createAdjustmentPreview", async (data: any): Promise<AdjustmentPreviewResponse> => {
    const {id, userEmail} = data;
    const payload: AdjustmentPreviewPayload = {
        data: {
            type: "adjustment_preview",
            attributes: {
                cancelled_by_email: userEmail,
            },
        },
    };
    try {
        const response = await resManService.createAdjustmentPreviewReservation(id, payload);
        //Dispatch event to datadog when a adjustment preview is successful created
        EventService.dispatch(datadogLogs, {
            title: "Adjustment Preview Success",
            message: `Reservation UUID: ${id}`,
            type: EventTypes.CANCELLATION_WORKFLOW_PREVIEW_SUCCESS,
            source: EventSources.UI,
            level: EventService.INFO_LEVEL,
            data: {payload},
        });
        return response;
    } catch (error) {
        const errorDetails: string = error?.data?.errors?.[0]?.detail || error?.message;
        //Dispatch event to datadog when a adjustment preview fails to be created
        EventService.dispatch(datadogLogs, {
            title: "Adjustment Preview Fail",
            message: `Reservation UUID: ${id}`,
            type: EventTypes.CANCELLATION_WORKFLOW_PREVIEW_FAIL,
            source: EventSources.UI,
            level: EventService.ERROR_LEVEL,
            data: {payload, response: errorDetails},
        });
        throw new Error(
            `Error creating the adjustment preview. Please reload the page or try again later. If this message persists, please contact support. Details: ${errorDetails}`
        );
    }
});

export interface financeState {
    payment: {
        creditCards: PaymentSummaryItem[];
        creditCardsParsed: CreditCardParsed[];
        totalAmountPaid: number;
        currencyCode: string;
        action: {
            fetching: boolean;
        };
        modal: {
            error: boolean;
            errorMessage: string;
        };
    };
    adjustmentPreview: {
        data: AdjustmentPreviewResponseData;
        totalOriginalCost: number;
        recommendedRefund: number;
        totalCancellationCharges: number;
        cancellationCharges: AdjustmentPreviewResponseFinances;
        finalRefund: number;
        action: {
            fetching: boolean;
        };
        modal: {
            error: boolean;
            errorMessage: string;
        };
    };
}

const initialState: financeState = {
    payment: {
        creditCards: null,
        creditCardsParsed: null,
        totalAmountPaid: null,
        currencyCode: null,
        action: {
            fetching: true,
        },
        modal: {
            error: false,
            errorMessage: null,
        },
    },
    adjustmentPreview: {
        data: null,
        totalOriginalCost: null,
        recommendedRefund: null,
        totalCancellationCharges: null,
        cancellationCharges: null,
        finalRefund: null,
        action: {
            fetching: true,
        },
        modal: {
            error: false,
            errorMessage: null,
        },
    },
};

const financeSlice = createSlice({
    name: "finance",
    initialState,
    reducers: {
        resetFinanceState: () => initialState,
        setOverrideAmounts: (state, {payload}) => {
            state.adjustmentPreview.finalRefund = payload?.finalRefund;
            state.payment.creditCardsParsed = payload?.creditCardsParsed;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReservationPaymentSummary.pending, (state) => {
                state.payment.action.fetching = true;
            })
            .addCase(getReservationPaymentSummary.fulfilled, (state, {payload}) => {
                state.payment.creditCards = payload?.data?.filter((item) => item.attributes.method === CREDIT_CARD);
                state.payment.totalAmountPaid = Number(payload?.meta?.amount?.total_in_dollars) || payload?.meta?.amount?.total;
                state.payment.currencyCode = payload?.data?.[0]?.attributes?.amount?.currency || "USD";
                state.payment.action.fetching = false;
            })
            .addCase(getReservationPaymentSummary.rejected, (state, {payload}: any) => {
                state.payment.modal.error = true;
                state.payment.modal.errorMessage = payload;
                state.payment.action.fetching = false;
            })
            .addCase(createAdjustmentPreview.pending, (state) => {
                state.adjustmentPreview.action.fetching = true;
            })
            .addCase(createAdjustmentPreview.fulfilled, (state, {payload}) => {
                state.adjustmentPreview.data = payload?.data;
                state.adjustmentPreview.totalCancellationCharges = Number(payload?.data?.attributes?.preview_finances?.total);
                state.adjustmentPreview.cancellationCharges = payload?.data?.attributes?.preview_finances;
                state.adjustmentPreview.totalOriginalCost = Number(payload?.data?.attributes?.original_finances?.total);
                state.adjustmentPreview.recommendedRefund =
                    state.payment.totalAmountPaid - state.adjustmentPreview.totalCancellationCharges < 0
                        ? 0
                        : state.payment.totalAmountPaid - state.adjustmentPreview.totalCancellationCharges;
                state.adjustmentPreview.finalRefund = Number(state.adjustmentPreview.recommendedRefund.toFixed(2));
                state.payment.creditCardsParsed = creditCardsParser(
                    state.payment.creditCards as PaymentSummaryItem[],
                    state.adjustmentPreview.recommendedRefund
                );
                state.adjustmentPreview.action.fetching = false;
            })
            .addCase(createAdjustmentPreview.rejected, (state, payload) => {
                state.adjustmentPreview.modal.error = true;
                state.adjustmentPreview.modal.errorMessage = payload?.error?.message;
                state.adjustmentPreview.action.fetching = false;
            });
    },
});

export const {resetFinanceState, setOverrideAmounts} = financeSlice.actions;

export default financeSlice.reducer;
