import React, {useState} from "react";
import cx from "classnames";
import styles from "./Dropzone.module.scss";
import {BsFileEarmarkText} from "react-icons/bs";

interface DropzoneProps {
  title: string;
  selectedFile: fileData;
  setFile: (fileData) => void;
}

interface fileData {
  name: string;
  type: string;
  fileBuffer: number[];
}

/**
 * Allows you to upload a file
 * @param DropzoneProps
 * @returns component
 */
export const Dropzone: React.FC<DropzoneProps> = ({title, setFile, selectedFile}) => {
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const fileData = await convertFileToData(file);
    setFile(fileData);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const fileData = await convertFileToData(file);
    setFile(fileData);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const convertFileToData = async (file) => {
    const buffer = await file.arrayBuffer();
    const bytes = new Uint8Array(buffer);
    const fileBuffer = Array.from(bytes);
    const fileData = {
      name: file.name,
      type: file.type,
      fileBuffer: fileBuffer,
    };
    return fileData;
  };

  return (
    <div style={{position: "relative"}} className={cx("box", "columnFlex")}>
      <div className={styles.titleAttachment}>
        <span className="boxTitle">{title}</span>
      </div>
      <div className={styles.segmentedBorder} onDrop={handleDrop} onDragOver={handleDragOver}>
        <div className={styles.uploadContainer}>
          <span className={styles.icon}>
            <BsFileEarmarkText />
          </span>
          <span className={styles.uploadText}>Drag and drop your documents here to start uploading</span>
        </div>
        <div className={styles.lineContainer}>
          <div className={styles.line}></div>
          <span className={styles.orText}>OR</span>
          <div className={styles.line}></div>
        </div>
        <input type="file" id="file-input" onChange={handleFileChange} style={{display: "none"}} />
        <label htmlFor="file-input" className={styles.browseButton}>
          Browse Files
        </label>
        {selectedFile && <p>Selected File: {selectedFile.name}</p>}
      </div>
    </div>
  );
};
