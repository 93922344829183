import React from "react";
import styles from "./ReservationDetails.module.scss";
import {FrontendConfiguration} from "@common/configuration";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {dayjs} from "../../utils";
import {ReservationDetailsLoader} from "../loadings/Loadings";

export const ReservationDetails: React.FunctionComponent<any> = () => {
  const reservation = useSelector((state: RootState) => state.cancellationWorkflow?.reservation);
  const fetchingReservation = useSelector((state: RootState) => state.cancellationWorkflow?.reservation.action.fetching);
  const createdReservationDate = reservation?.data?.attributes?.created_at;
  const createdReservationDateFormatted = dayjs.utc(createdReservationDate).tz(FrontendConfiguration.getTimeZone());
  const date = dayjs(createdReservationDateFormatted).format("YYYY-MM-DD");
  const time = dayjs(createdReservationDateFormatted).format("h:mm A");

  return fetchingReservation ? (
    <ReservationDetailsLoader />
  ) : (
    <>
      <div className={styles.detailsItem}>
        <div className={styles.created}>Created</div>
        {createdReservationDate ? (
          <>
            <span className={styles.highlight}>{date}</span>
            <span className={styles.createdHour}>{time}</span>
          </>
        ) : (
          <span className={styles.highlight}>No Information</span>
        )}
      </div>
      <div className={styles.detailsItem}>
        <div className={styles.reservationId}>Res ID</div>
        <span className={styles.highlight}>{reservation?.data?.attributes?.legacy_reservation_id || "No Information"}</span>
      </div>
    </>
  );
};
