import {Visa, Mastercard, Placeholder} from "../../assets/images";
import {CreditCardParsed, PaymentSummaryItem} from "@common/typing";
import {MASTERCARD, MC, VISA} from "../../constants";

export const creditCardsParser = (creditCards: any, totalRefund = 0): CreditCardParsed[] => {
    return creditCards
        ?.map((card: PaymentSummaryItem) => {
            let methodType: string = card?.attributes?.methodType;
            let image: string;

            switch (methodType) {
                case VISA:
                    image = Visa;
                    break;
                case MASTERCARD:
                    image = Mastercard;
                    break;
                case MC:
                    image = Mastercard;
                    break;
                default:
                    image = Placeholder;
                    break;
            }

            return {
                logo: image,
                lastDigits: card?.attributes?.lastFour,
                payment_date: card.attributes?.created_at ?? "",
                currency: card?.attributes?.amount?.currency,
                paid: card?.attributes?.amount?.total,
            };
        })
        .reverse()
        .reduce(
            (acc, curr, index, array) => {
                curr.refund = acc[1] - curr.paid >= 0 ? curr.paid : acc[1] > 0 ? acc[1] : 0;
                return [array, acc[1] - curr.paid];
            },
            [[], totalRefund]
        )[0];
};
