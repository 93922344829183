import {AdjustmentPreviewFee, NewRefundAmount, OverrideAmountOption, ReasonSelected} from "@common/typing";
import {Button, Icon, Modal, RadioButton} from "@vacasa/react-components-lib";
import React, {useEffect, useState} from "react";
import {SearchBar, TextArea} from "../../components";
import {
  FORCED_MOVE,
  FSC,
  TOTAL_PAID_MINUS_BOOKING_FEE,
  CUSTOM_REFUND,
  BOOKING_FEE_NAME,
  TOTAL_PAID_MINUS_BOOKING_FEE_AND_TP,
  TRIP_PROTECTION_FEE_NAME,
} from "../../constants";
import {overrideAmountOptions} from "../../data/staticData";
import {creditCardsParser, formattedCustomAmounts, redirectToURL} from "../../utils";
import {
  isValidAuthorization,
  isValidCustomRefund,
  isValidCustomTripProtection,
  isValidNote,
  isValidReason,
  isValidRefundOptionSelected,
} from "../../utils/";
import {Select} from "../select/Select";
import styles from "./OverrideAmounts.module.scss";
import {RootState, useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {resetCustomRefundOption, setOverrideInfo, setOverrideSectionValid} from "../../store/cancellationWorkflowSlice";
import {setOverrideAmounts} from "../../store/financeSlice";
import {LegacyUrlGenerator} from "@common/utils";

export const OverrideAmounts = (): JSX.Element => {
  //Redux State
  const dispatch = useAppDispatch();
  const overrideData = useSelector((state: RootState) => state.cancellationWorkflow?.override);
  const reservation = useSelector((state: RootState) => state.cancellationWorkflow?.reservation?.data);
  const fees = useSelector((state: RootState) => state?.finance?.adjustmentPreview?.data?.attributes?.preview_finances?.fees);
  const totalAmountPaid = useSelector((state: RootState) => state?.finance?.payment?.totalAmountPaid);
  const reservationHasTripProtection = useSelector((state: RootState) => state?.cancellationWorkflow?.reservation?.hasTripProtection);
  const overrideReasons = useSelector((state: RootState) => state.cancellationWorkflow.override.overrideReasons);
  const creditCards = useSelector((state: RootState) => state.finance.payment.creditCards);

  //Local State
  const [reasonSelected, setReasonSelected] = useState<ReasonSelected>({
    id: overrideData?.reason?.id || "",
    name: overrideData?.reason?.name || "",
    requiredNote: overrideData?.reason?.requiredNote || false,
  });

  const [newRefundAmountOption, setNewRefundAmountOption] = useState<NewRefundAmount>({
    refundAmount: overrideData?.refund?.refundAmount || "",
    refundAmountOption: overrideData?.refund?.refundAmountOption || "",
    allowCustomRefund: overrideData?.refund?.allowCustomRefund || false,
    tripProtectionAmount: overrideData?.refund?.tripProtectionAmount || "",
  });

  const [authorizeBy, setAuthorizeBy] = useState<string>(overrideData?.authorizeBy || "");
  const [overrideNotes, setOverrideNotes] = useState<string>(overrideData?.notes || "");

  const [isDisabledOverrideButton, setIsDisabledOverrideButton] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [requiredValues, setRequiredValues] = useState<string>("");
  const [customTpValue, setCustomTpValue] = useState<string>("");

  const bookingFeeAmount: number = Number(fees?.filter((fee: AdjustmentPreviewFee) => fee.name === BOOKING_FEE_NAME)[0]?.amount) || 0;
  const tripProtectionFeeAmount: number = Number(fees.filter((fee: AdjustmentPreviewFee) => fee.name === TRIP_PROTECTION_FEE_NAME)[0]?.amount || 0);
  const totalAmountPaidMinusBookingFee: number = Number((totalAmountPaid - bookingFeeAmount).toFixed(2));
  const totalAmountPaidMinusBookingFeeAndTpFee: number = Number((totalAmountPaid - bookingFeeAmount - tripProtectionFeeAmount).toFixed(2));
  let newTotalRefund: number;

  const overrideAmountOptionsToDisplay = overrideAmountOptions
    //Filter the override amount options to only include the ones that are valid for the reservation (booking fee or trip protection fee)
    .filter((option: OverrideAmountOption) => {
      if (totalAmountPaid > 0 && reservationHasTripProtection) {
        return option.id === TOTAL_PAID_MINUS_BOOKING_FEE_AND_TP || option.id === CUSTOM_REFUND;
      } else if (totalAmountPaid > 0 && !reservationHasTripProtection) {
        return option.id === TOTAL_PAID_MINUS_BOOKING_FEE || option.id === CUSTOM_REFUND;
      } else {
        return option.id === CUSTOM_REFUND;
      }
    })
    //Map the override amount options to update the amount based on the reservation total amount paid and the fees
    .map((overrideOption: OverrideAmountOption) => {
      if (totalAmountPaid === 0) {
        newTotalRefund = 0;
      } else if (totalAmountPaid > 0 && reservationHasTripProtection) {
        newTotalRefund = totalAmountPaidMinusBookingFeeAndTpFee;
      } else {
        newTotalRefund = totalAmountPaidMinusBookingFee;
      }

      return {
        ...overrideOption,
        amount: newTotalRefund,
      };
    });

  useEffect(() => {
    checkStepButton();
    setIsDisabledOverrideButton(!checkStepButton());
    if (!checkStepButton() && overrideData?.isValid) dispatch(setOverrideSectionValid(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizeBy]);

  useEffect(() => {
    checkStepButton();
    setIsDisabledOverrideButton(!checkStepButton());
    dispatch(
      setOverrideInfo({
        ...overrideData,
        reason: reasonSelected,
      })
    );
    if (!checkStepButton() && overrideData?.isValid) dispatch(setOverrideSectionValid(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reasonSelected]);

  useEffect(() => {
    checkStepButton();
    setIsDisabledOverrideButton(!checkStepButton());
    dispatch(
      setOverrideInfo({
        ...overrideData,
        refund: newRefundAmountOption,
      })
    );
    dispatch(setOverrideSectionValid(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRefundAmountOption]);

  useEffect(() => {
    checkStepButton();
    setIsDisabledOverrideButton(!checkStepButton());
    dispatch(
      setOverrideInfo({
        ...overrideData,
        notes: overrideNotes,
      })
    );
    if (!checkStepButton() && overrideData?.isValid) dispatch(setOverrideSectionValid(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overrideNotes]);

  const checkStepButton = () => {
    checkRequiredValues();
    return (
      isValidReason(reasonSelected.name) &&
      isValidRefundOptionSelected(newRefundAmountOption.refundAmountOption) &&
      isValidAuthorization(overrideData?.authorizeBy) &&
      isValidCustomRefund(newRefundAmountOption) &&
      isValidNote(reasonSelected.requiredNote, overrideNotes) &&
      isValidCustomTripProtection(customTpValue, reservationHasTripProtection, newRefundAmountOption.refundAmountOption)
    );
  };

  const handleClickAmountOption = (optionSelected: number, totalAmount: string, allowCustomRefund: boolean) => {
    //Reset the custom refund amount and custom trip protection amount when the user selects a new option
    dispatch(resetCustomRefundOption());
    setCustomTpValue("");

    //Update the new refund amount option with the selected option and the total amount
    setNewRefundAmountOption({
      ...newRefundAmountOption,
      refundAmount: allowCustomRefund ? "" : totalAmount,
      refundAmountOption: optionSelected.toString(),
      allowCustomRefund: allowCustomRefund,
    });
  };

  const handleClickOverride = () => {
    //If the user selects FSC or Forced Move we show a modal with a message (these options are not supported in the new ResMan UI)

    //TODO: Ask Kevin if we should remove this modal and allow the user to select these options. For a while it's commented
    // if (reasonSelected.name === FSC || reasonSelected.name === FORCED_MOVE) {
    //   setShowModal(true);
    //   return;
    // }

    //We set as valid the override section when the user clicks on the override button
    dispatch(setOverrideSectionValid(true));

    //Update the override data with the new values to display the new amounts in the credit cards
    const creditCardsWithOverride = creditCardsParser(creditCards, Number(newRefundAmountOption.refundAmount));
    dispatch(
      setOverrideAmounts({
        finalRefund: Number(Number(newRefundAmountOption.refundAmount).toFixed(2)),
        creditCardsParsed: creditCardsWithOverride,
      })
    );
  };

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const invalidChars = ["-", "+", "e"];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;

    if (Number(value) > totalAmountPaid) return;

    switch (name) {
      case "customRefund":
        if (Number(value) > Number((totalAmountPaid - Number(customTpValue)).toFixed(2))) return;
        setNewRefundAmountOption({
          ...newRefundAmountOption,
          refundAmount: formattedCustomAmounts(value),
        });
        break;
      case "customTripProtection":
        if (Number(value) > Number((totalAmountPaid - Number(newRefundAmountOption.refundAmount)).toFixed(2))) return;
        setCustomTpValue(formattedCustomAmounts(value));
        setNewRefundAmountOption({
          ...newRefundAmountOption,
          tripProtectionAmount: formattedCustomAmounts(value),
        });
        break;
      default:
        break;
    }
  };

  const handleChangeNotes = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const notes = event?.currentTarget?.value;
    setOverrideNotes(notes);
  };

  const checkRequiredValues = () => {
    let requiredValueToDisplay: string;

    const isReasonValid = isValidReason(reasonSelected?.name);
    const isRefundOptionSelected = isValidRefundOptionSelected(newRefundAmountOption?.refundAmountOption);
    const isAuthorizationValid = isValidAuthorization(overrideData?.authorizeBy);
    const isCustomRefundValid = isValidCustomRefund(newRefundAmountOption);
    const isNoteValid = isValidNote(reasonSelected.requiredNote, overrideNotes);
    const isCustomTripProtectionValid = isValidCustomTripProtection(
      customTpValue,
      reservationHasTripProtection,
      newRefundAmountOption.refundAmountOption
    );

    if (!isReasonValid) {
      requiredValueToDisplay = "Override reason";
    } else if (!isRefundOptionSelected) {
      requiredValueToDisplay = "Refund amount option";
    } else if (!isAuthorizationValid) {
      requiredValueToDisplay = "Authorizer";
    } else if (!isCustomRefundValid) {
      requiredValueToDisplay = "Custom refund amount";
    } else if (!isNoteValid) {
      requiredValueToDisplay = "Note";
    } else if (!isCustomTripProtectionValid) {
      requiredValueToDisplay = "Custom trip protection amount";
    } else {
      requiredValueToDisplay = "";
    }

    setRequiredValues(requiredValueToDisplay);
  };

  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal} size="small" canExit={true}>
        <div className={styles.modal}>
          <span className={styles.dangerText}>FSC</span> and <span className={styles.dangerText}>Forced Move</span> are not currently supported in the
          new ResMan UI. You can use ResEdit to create the
          <span className={styles.dangerText}> FSC</span> after you finish canceling this reservation.
          <span
            className={styles.reseditLink}
            onClick={() => redirectToURL(LegacyUrlGenerator.toReservation(reservation?.attributes?.legacy_reservation_id))}
          >
            {" "}
            Go to Resedit
          </span>
        </div>
      </Modal>
      <Select
        label={"Override reason"}
        data={overrideReasons}
        onSelectChange={setReasonSelected}
        value={reasonSelected.name}
        placeholder="Select a reason"
      />

      <span className={styles.title}>New refund amount</span>
      {
        <div className={styles.optionsContainer}>
          {overrideAmountOptionsToDisplay?.map((option: any) => (
            <RadioButton
              key={option.id}
              checked={newRefundAmountOption.refundAmountOption?.toString()}
              label={`${option.option} ${!option.allowCustomRefund ? `($${option.amount})` : ""}`}
              setter={(optionSelected: number) => handleClickAmountOption(optionSelected, option.amount, option.allowCustomRefund)}
              value={option.id.toString()}
              disabled={false}
            />
          ))}
        </div>
      }
      {newRefundAmountOption.allowCustomRefund && (
        <div className={styles.amountOptionsContainer}>
          <input
            name="customRefund"
            className={styles.input}
            type="number"
            placeholder="$0.00"
            min="0"
            value={newRefundAmountOption.refundAmount}
            onChange={handleAmountChange}
            onKeyPress={handleInputKeyPress}
          />
          {reservationHasTripProtection && (
            <div className={styles.amountOptionsContainerTripProtection}>
              <p className={styles.customTpTitle}>Custom TP</p>
              <input
                name="customTripProtection"
                className={styles.input}
                type="number"
                placeholder="$0.00"
                min="0"
                value={customTpValue}
                onChange={handleAmountChange}
                onKeyPress={handleInputKeyPress}
              />
            </div>
          )}
        </div>
      )}

      <div className={styles.searchBarContainer}>
        <SearchBar value={authorizeBy} title="Authorize by" placeholder="Start typing..." onSearchChange={setAuthorizeBy} />
      </div>

      <div className={styles.notesContainer}>
        <TextArea title={`Note ${reasonSelected.requiredNote ? "(required):" : "(optional):"}`} value={overrideNotes} onChange={handleChangeNotes} />
      </div>

      {requiredValues !== "" && (
        <span className={styles.requiredValues}>
          You need to select a valid <span className={styles.dangerText}>{requiredValues}</span>
        </span>
      )}

      <div className={styles.buttonContainer}>
        <Button onClick={handleClickOverride} variant={"info"} disabled={isDisabledOverrideButton}>
          Apply Override {overrideData?.isValid && <Icon.CheckCircle className={styles.icon} height={24} width={24} />}
        </Button>
      </div>
    </>
  );
};
