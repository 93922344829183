import React from "react";
import {VerticalTimeline} from "../vertical-timeline/VerticalTimeline";
import {FiCheck, FiCalendar} from "react-icons/fi";
import {isNil} from "lodash";
import cx from "classnames";
import styles from "./CancellationSchedule.module.scss";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {CancellationScheduleLoader} from "../loadings/Loadings";
import {getTripProtectionProviderName} from "../../utils";

interface CancellationScheduleProps {
  title: string;
  schedule: any; //TODO: Add type
}

export const CancellationSchedule: React.FunctionComponent<CancellationScheduleProps> = ({title, schedule}) => {
  const fetchingData = useSelector(
    (state: RootState) =>
      state.cancellationWorkflow.reservation.action.fetching ||
      state.cancellationWorkflow.unit.action.fetching ||
      state.finance.adjustmentPreview.action.fetching ||
      state.finance.payment.action.fetching
  );

  return (
    <>
      <div className={styles.cancellationScheduleBox}>
        {fetchingData ? (
          <CancellationScheduleLoader />
        ) : (
          <>
            <div className={cx(styles.title, "fontHeadingCard")}>{title}</div>
            {isNil(schedule) ? (
              <div>
                Work in progress.
                <br />
                More details coming soon.
              </div>
            ) : (
              <>
                <VerticalTimeline events={schedule?.scheduleParsedEvents} />
                {!isNil(schedule?.daysUntilCheckIn) && schedule?.daysUntilCheckIn >= 0 && (
                  <div className={cx(styles.additional, "flex")}>
                    <div className={cx("columnFlex", styles.additionalInfoIconBox)}>
                      <FiCalendar className={styles.additionalInfoIcon} size={18} style={{color: "#2C3439"}} />
                    </div>
                    <div className={cx("columnFlex", "fontBodyRegularDefault", styles.additionalInfoDesc)}>
                      {schedule?.daysUntilCheckIn === 1 ? `${schedule?.daysUntilCheckIn} day` : `${schedule?.daysUntilCheckIn} days`} until check-in
                    </div>
                  </div>
                )}
                {!isNil(schedule.tripProtection) && (
                  <div className={cx(styles.additional, "flex")}>
                    <div className={cx("columnFlex", styles.additionalInfoIconBox)}>
                      <span className={styles.additionalInfoIcon}>
                        <FiCheck size={18} style={{color: "green"}} />
                      </span>
                    </div>
                    <div className={"columnFlex"}>
                      {schedule.tripProtection.id ? getTripProtectionProviderName(schedule.tripProtection?.id) : schedule.tripProtection?.desc} on
                      reservation
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
