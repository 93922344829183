import React from "react";
import {BankCardAmount, BankCardInfo, AmountInfo} from "..";
import {toLocalString} from "../../utils";
import styles from "./ReviewRefund.module.scss";
import cx from "classnames";
import {useSelector} from "react-redux";
import {RootState} from "src/store/store";

export const ReviewRefund: React.FunctionComponent<any> = () => {
  const creditCards = useSelector((state: RootState) => state.finance.payment?.creditCardsParsed);

  return (
    <div style={{position: "relative"}} className={cx("box", "columnFlex")}>
      <h2 className={"boxTitle"}>Details of Refund</h2>
      <span>Refund going to</span>
      <p className={styles.responses}>Original payment method(s)</p>
      {creditCards?.map((card, idx) => (
        <React.Fragment key={idx}>
          <BankCardAmount
            left={<BankCardInfo img={card.logo} lastDigits={card.lastDigits} description={`Guest paid ${toLocalString(card.paid, card.currency)}`} />}
            right={<AmountInfo amount={card.refund} prefix={card.currency} editable={false} maxAmount={card.paid} type="review" />}
            editable={false}
          />
          {idx < creditCards?.length - 1 ? <div className={styles.separator}>{}</div> : null}
        </React.Fragment>
      ))}
    </div>
  );
};
