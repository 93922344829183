import React, {useLayoutEffect} from "react";
import {BackSection, ReservationDetails, ExtendedReservationDetails} from "./../../components";
import styles from "./Header.module.scss";
import cx from "classnames";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {
  displayReservationModalError,
  getOverrideReasons,
  getReservationById,
  getUnitById,
  setReservationModalErrorMessage,
} from "../../store/cancellationWorkflowSlice";
import {createAdjustmentPreview, getReservationPaymentSummary} from "../../store/financeSlice";
import {getUserInfo} from "../../utils";
import {matchPath} from "react-router";
import {useLocation} from "react-router-dom";

export const Header = () => {
  const dispatch = useAppDispatch();
  const reservation = useSelector((state: RootState) => state.cancellationWorkflow.reservation);
  const isAirbnbChannel = reservation?.data?.isAirbnbChannel;
  const currentPath = useSelector((state: RootState) => state.cancellationWorkflow.currentPathname);
  const fetchingFinance = useSelector((state: RootState) => state.finance.payment.action.fetching);
  const {email: userEmail} = getUserInfo();
  const {pathname} = useLocation();

  const getParamsFromPath = (path: string) => {
    const match = matchPath(pathname, {path});
    return match?.params || {};
  };
  const {id: reservationId}: any = getParamsFromPath("/:id");
  const isValidReservationId = reservationId !== "undefined" && reservationId !== undefined;

  useLayoutEffect(() => {
    if (!isValidReservationId) {
      dispatch(displayReservationModalError());
      dispatch(setReservationModalErrorMessage("Invalid reservation ID. Please make sure the reservation ID is correct."));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId]);

  useLayoutEffect(() => {
    async function fetch() {
      await dispatch(getReservationById(reservationId));
      await dispatch(getReservationPaymentSummary(reservationId));
      await dispatch(getOverrideReasons());
    }

    if (isValidReservationId) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const unitId = reservation?.data?.attributes?.unit_id;
    const reservationUuid = reservation?.data?.id;
    async function fetch() {
      unitId && (await dispatch(getUnitById(unitId)));
      reservationUuid && (await dispatch(createAdjustmentPreview({id: reservationUuid, userEmail})));
    }

    if (reservation && !fetchingFinance && isValidReservationId && !currentPath?.includes("review")) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation, fetchingFinance]);

  return (
    <div className={"rowFlex"}>
      <div className={cx(styles.backTitle, "columnFlex")}>
        <BackSection reservationId={reservationId} />
      </div>
      <div className={cx(styles.reservationDetails)}>{isAirbnbChannel ? <ExtendedReservationDetails /> : <ReservationDetails />}</div>
    </div>
  );
};
