import React, {useState} from "react";
import {AmountInfo, BankCardAmount, BankCardInfo, Button} from "../";
import {toLocalString} from "../../utils";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import styles from "./CreditCard.module.scss";

export const CreditCard = () => {
  const [editableAmount, setEditableAmount] = useState(false);
  const disableAmountCard = true;
  const amountCardType = "text";
  const creditCardsParsed = useSelector((state: RootState) => state.finance.payment.creditCardsParsed);

  const handleClickEditAmount = () => {
    if (disableAmountCard) return;
    setEditableAmount(true);
  };

  return (
    <>
      <p className={styles.creditCardInfo}>
        The refund will go back to the {creditCardsParsed?.length} cards the guest has used. It will be appear on their records within 2-3 business
        days
      </p>
      {creditCardsParsed?.map((card, index) => {
        return (
          <React.Fragment key={index}>
            <BankCardAmount
              left={
                <BankCardInfo
                  img={card?.logo}
                  lastDigits={card?.lastDigits}
                  description={`Guest paid ${toLocalString(card?.paid, card?.currency)}`}
                />
              }
              right={
                <AmountInfo
                  amount={card?.refund}
                  prefix={card?.currency}
                  editable={editableAmount}
                  maxAmount={card?.paid}
                  type={amountCardType}
                  onClick={handleClickEditAmount}
                />
              }
              editable={editableAmount}
            />
            {index < creditCardsParsed?.length - 1 ? <div className={styles.separator}>{}</div> : null}
          </React.Fragment>
        );
      })}
      {editableAmount && (
        <div className={styles.btnUpdate}>
          <Button
            onClick={() => {
              setEditableAmount(false);
            }}
            label={"Update"}
            mode={"secondaryOutlinedNarrow"}
            disabled={false}
          />
        </div>
      )}
    </>
  );
};
