import React from "react";
import styles from "./Checkbox.module.scss";
import {Help} from "../help/Help";
import {RadioButton} from "@vacasa/react-components-lib";

interface CheckboxProps {
  question: string;
  info: string;
  options: CheckboxOptionProps[];
  value: boolean;
  onChange: (value: boolean) => void;
}

interface CheckboxOptionProps {
  text: string;
  value: boolean;
}

/**
 * Generates a checkbox with the data provided. It is dynamic so that it can be reused in the future.
 * @param CheckboxProps
 * @returns component
 */
export const Checkbox: React.FC<CheckboxProps> = ({question, info, options, value, onChange}) => {
  const handleOptionChange = (option: boolean) => {
    onChange(option);
  };

  return (
    <div className={"columnFlex"} style={{display: "flex"}}>
      <div>
        <span className={styles.title}>{question}</span>
        <Help info={info} />
      </div>
      {options.map((option, index) => (
        <RadioButton
          key={index}
          checked={value.toString()}
          label={option.text}
          setter={(optionSelected: boolean) => handleOptionChange(option.value)}
          value={option.value.toString()}
          disabled={false}
        />
      ))}
    </div>
  );
};
