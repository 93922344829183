import React from "react";
import {OTHER_REASON} from "../../constants";
import styles from "./Select.module.scss";

interface Props {
  data: Array<any>;
  value: any;
  onSelectChange: (event: any) => void;
  label?: string;
  placeholder?: string;
}

export const Select = ({data, onSelectChange, label, placeholder, value}: Props): JSX.Element => {
  const handleChange = (event: any) => {
    const requiredNote = event.target.value === OTHER_REASON;
    const reasonId = event.target[event.target.selectedIndex].getAttribute("data-id");

    if (onSelectChange)
      onSelectChange({
        id: reasonId,
        name: event.target.value,
        requiredNote,
      });
  };

  const options = data?.map((data: any) => (
    <option key={data.id} value={data.attributes.slug} data-id={data.id}>
      {data.attributes.name}
    </option>
  ));

  return (
    <div className={"columnFlex"}>
      <div className={styles.label}>{label && <span className={"label"}>{label}</span>}</div>
      {data && (
        <select value={value} className={[styles["custom-select"], styles.select].join(" ")} onChange={handleChange}>
          <option value="0">{placeholder && placeholder}</option>
          {options}
        </select>
      )}
    </div>
  );
};
