import React, {useState, useEffect} from "react";
import styles from "./EmailBody.module.scss";
import logo from "../../../assets/images/vacasa_identity_lockup_horizontal_blue.svg";
import cx from "classnames";
import {FrontendConfiguration} from "@common/configuration";
import {getTripProtectionProviderInfo, toLocalString} from "../../../utils";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../store/store";
import {setEmailData} from "../../../store/cancellationWorkflowSlice";
import {TripProtectionProviders} from "../../../constants";
dayjs.extend(utc);
dayjs.extend(timezone);

export const EmailBody: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const reservation = useSelector((state: RootState) => state.cancellationWorkflow.reservation);
  const unit = useSelector((state: RootState) => state.cancellationWorkflow.unit);
  const paymentData = useSelector((state: RootState) => state.finance.payment);
  const adjustmentPreview = useSelector((state: RootState) => state.finance.adjustmentPreview);
  const emailData = useSelector((state: RootState) => state.cancellationWorkflow.email.data);

  const firstNight = dayjs.tz(reservation?.data?.attributes?.first_night, FrontendConfiguration.getTimeZone());
  const lastNight = dayjs.tz(reservation?.data?.attributes?.last_night, FrontendConfiguration.getTimeZone());
  const now = dayjs.tz(dayjs(), FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD");
  const daysUntilCheckin = firstNight.diff(now, "day");
  const tripProtection = useSelector((state: RootState) => state.cancellationWorkflow.cancellationSchedule.tripProtection);
  const tripProtectionInformation = getTripProtectionProviderInfo(tripProtection?.provider);
  const cancelForAnyReasonMessage =
    tripProtection?.id !== TripProtectionProviders.PLAY_TRAVEL_PROTECTION_CFAR
      ? ` Please be aware, ${tripProtectionInformation?.name} will only approve unforeseen covered events.`
      : "";

  const tripProtectionNote: string = reservation?.hasTripProtection
    ? `We’re sorry to hear about your need to cancel your reservation. Since you are ${daysUntilCheckin} ${
        daysUntilCheckin === 1 ? "day" : "days"
      } from your check-in date, our non-refundable cancellation penalty of $${
        Number(paymentData?.totalAmountPaid - adjustmentPreview?.finalRefund).toFixed(2) || 0
      } applies. It looks like you previously secured travel protection through our travel insurance provider ${
        tripProtectionInformation?.name
      }. You are welcome to file a claim for any reservation costs that have not been refunded back to you.${cancelForAnyReasonMessage} All canceled reservations are not subject to policy approval. In order to file a claim with ${
        tripProtectionInformation?.name
      }, please go to ${
        tripProtectionInformation?.link
      } to start your trip cancellation claim online. If you have any questions regarding the coverage of your policy or questions on how to file a claim, please call ${
        tripProtectionInformation?.name
      }'s customer service toll free at ${tripProtectionInformation?.phone} and they would be happy to assist you.`
    : "";

  const [messageBody, setMessageBody] = useState(`${tripProtectionNote} ${emailData.bodyInformation}`);

  useEffect(() => {
    dispatch(setEmailData({...emailData, bodyInformation: tripProtectionNote}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageBody(evt.target.value.replace(/\n/gi, " "));
    dispatch(setEmailData({...emailData, bodyInformation: evt.target.value.replace(/\n/gi, " ")}));
  };

  return (
    <div className={styles.bodyEmail}>
      <div className={styles.emailContent}>
        <img src={logo} alt={"vacasa horizontal logo"} className={styles.logo} />
        <div className={styles.title}>Confirmation of reservation cancellation</div>
        <div className={styles.subtitle}>Your reservation in {unit?.data?.address?.city} has been successfully cancelled. </div>
        <table>
          <tbody className={styles.table}>
            <tr>
              <td className={styles.unitName}>{unit?.data?.name}</td>
              <td className={cx(styles.reservationCode, styles.tableRightColumn)}>
                Reservation: {reservation?.data?.attributes.confirmation_code ?? reservation?.data?.attributes?.legacy_reservation_id}
              </td>
            </tr>
            <tr>
              <td>
                <span className={styles.dateLabel}>Check in</span>
                <br />
                {firstNight.format("MMM DD YYYY")}
              </td>
              <td className={styles.tableRightColumn}>
                <span className={styles.dateLabel}>Check out</span>
                <br />
                {lastNight.add(1, "day").format("MMM DD YYYY")}
              </td>
            </tr>
          </tbody>
        </table>
        <p>Optional note to Guest:</p>
        <textarea className={styles.textAreaNote} onChange={handleChange} value={messageBody} />
        {paymentData?.totalAmountPaid === 0 ? ( // NO PAID
          <p>This trip is not eligible for a refund because no payment was received.</p>
        ) : paymentData?.totalAmountPaid > 0 && adjustmentPreview?.finalRefund === 0 ? ( // PAID AND NO REFUND
          <p>This trip was not eligible for a refund.</p>
        ) : (
          <>
            {adjustmentPreview?.finalRefund > 0 && paymentData?.totalAmountPaid > adjustmentPreview?.finalRefund ? ( // PARTIAL REFUND
              <>
                <p>You will receive a refund of {toLocalString(adjustmentPreview?.finalRefund, paymentData?.currencyCode)}.</p>
                <p>
                  Refunds will be issued in the original amounts less the amounts of Vacasa's booking fee and associated tax to the following cards:{" "}
                  {paymentData?.creditCardsParsed
                    ?.map((e) => e.lastDigits)
                    .toString()
                    .replace(/,/g, ", ")}
                  .
                </p>
              </>
            ) : paymentData?.totalAmountPaid > 0 && paymentData?.totalAmountPaid === adjustmentPreview?.finalRefund ? ( // FULL REFUND
              <>
                <p>
                  You will receive a refund of {toLocalString(adjustmentPreview?.finalRefund, paymentData?.currencyCode)}{" "}
                  {
                    paymentData?.creditCardsParsed?.length > 1 ? (
                      <>
                        to the following cards:{" "}
                        {paymentData?.creditCardsParsed
                          ?.map((e) => e.lastDigits)
                          .toString()
                          .replace(/,/g, ", ")}
                        .
                      </> // TO MULTIPLE CREDIT CARDS
                    ) : (
                      <>on your credit card ending in {paymentData?.creditCardsParsed[0]?.lastDigits}.</>
                    ) // TO SINGLE CREDIT CARD
                  }
                </p>
              </>
            ) : null}
            <>
              <p>Refund time depends on your payment method any may take up to 10 business days.</p>
              <p>Please note that all transactions are processed in their original booked currency. ({paymentData?.currencyCode})</p>
            </>
          </>
        )}
        <br />
        <p>
          Have questions? Please visit our{" "}
          <a target={"_blank"} rel="noopener noreferrer" href={FrontendConfiguration.getSupportAndContactLink()}>
            Support and Contact page.
          </a>
        </p>
      </div>
    </div>
  );
};
