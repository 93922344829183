import React from "react";
import styles from "./Help.module.scss";
import {FiInfo, FiX} from "react-icons/fi";

interface HelpProps {
  info: string;
}

/**
 * Generates a help label, to help with information about the action to be performed
 * @param string info
 * @returns component
 */
export const Help: React.FunctionComponent<HelpProps> = ({info}) => {
  return (
    <div
      onMouseEnter={(event) => {
        event.currentTarget.getElementsByClassName(styles.tooltipContentHidden)[0].classList.add(styles.tooltipContentVisible);
      }}
      className={styles.tooltipWrapper}
    >
      <FiInfo />
      <div className={styles.tooltipContentHidden}>
        <div style={{alignSelf: "flex-start"}}>
          <FiInfo size={22} />
        </div>
        <div style={{margin: "0 10px"}}>{info}</div>
        <div
          style={{alignSelf: "start", cursor: "pointer"}}
          onClick={(event) => {
            event.currentTarget.parentElement.classList.remove(styles.tooltipContentVisible);
          }}
        >
          <FiX size={22} style={{alignSelf: "center"}} />
        </div>
      </div>
    </div>
  );
};
