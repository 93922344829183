import {configureStore} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import CancellationWorkflowReducer from "./cancellationWorkflowSlice";
import FinanceReducer from "./financeSlice";

export const store = configureStore({
    reducer: {
        cancellationWorkflow: CancellationWorkflowReducer,
        finance: FinanceReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
