import React from "react";
import {JustifiedTable} from "../justified-table/JustifiedTable";
import {cancelPreviewParser, cancelPreviewParserMinify} from "../../utils";
import {useSelector} from "react-redux";
import {RootState} from "src/store/store";
import styles from "./RefundSummary.module.scss";
import {RefundSummaryLoader} from "../loadings/Loadings";

type Props = {
  title: string;
};

export const RefundSummary = ({title}: Props): JSX.Element => {
  //Redux State
  const currencyCode = useSelector((state: RootState) => state.finance?.payment?.currencyCode || "USD");
  const reservation = useSelector((state: RootState) => state.cancellationWorkflow?.reservation);
  const isAirbnbChannel = reservation?.data?.isAirbnbChannel;
  const totalAmountPaid = useSelector((state: RootState) => state.finance.payment.totalAmountPaid);
  const paymentError = useSelector((state: RootState) => state.finance.payment?.modal.error);
  const adjustmentPreviewError = useSelector((state: RootState) => state.finance.adjustmentPreview?.modal.error);
  const totalCancellationCharges = useSelector((state: RootState) => state.finance.adjustmentPreview.totalCancellationCharges);
  const recommendedRefund = useSelector((state: RootState) => state.finance.adjustmentPreview.recommendedRefund);
  const fetchingData = useSelector(
    (state: RootState) =>
      state.cancellationWorkflow.reservation.action.fetching ||
      state.cancellationWorkflow.unit.action.fetching ||
      state.finance.adjustmentPreview.action.fetching ||
      state.finance.payment.action.fetching
  );
  const cancellationCharges = useSelector((state: RootState) => state.finance.adjustmentPreview?.data?.attributes?.preview_finances);
  const originalCost = useSelector((state: RootState) => state.finance.adjustmentPreview?.totalOriginalCost);
  const fees = cancellationCharges?.fees;
  const taxes = cancellationCharges?.taxes;
  const rents = cancellationCharges?.rent;

  const totalFees =
    fees &&
    fees.reduce((acc, item) => {
      return acc + Number(item.amount);
    }, 0);
  const totalTaxes =
    taxes &&
    taxes.reduce((acc, item) => {
      return acc + Number(item.amount);
    }, 0);
  const totalRents =
    rents &&
    rents.reduce((acc, item) => {
      return acc + Number(item.rent);
    }, 0);

  const cancelPreviewData = {
    originalCost,
    currencyCode,
    totalAmountPaid,
    totalCancellationCharges,
    totalTaxes,
    totalFees,
    totalRents,
    recommendedRefund,
  };

  return (
    <>
      <div className={styles.financialSummary}>
        {fetchingData || adjustmentPreviewError || paymentError ? (
          <RefundSummaryLoader />
        ) : (
          <>
            <h2 className={"boxTitle"}>{title}</h2>
            {isAirbnbChannel ? (
              <JustifiedTable rows={cancelPreviewParserMinify(cancelPreviewData)[0]?.data} />
            ) : (
              <JustifiedTable rows={cancelPreviewParser(cancelPreviewData)[0]?.data} />
            )}
          </>
        )}
      </div>
    </>
  );
};
