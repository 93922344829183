import React, {useEffect, useLayoutEffect, useState} from "react";
import {Select} from "../inputs/select/Select";
import {TextArea} from "../inputs/textarea/TextArea";
import {Checkbox} from "../inputs/checkbox/Checkbox";
import {WarningCancellationAirbnb} from "../modals/Modals";
import {aboutCancellationStaticData} from "../../data/staticData";
import styles from "./AboutCancellation.module.scss";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {setAboutCancellationData, setValidCancellation} from "../../store/cancellationWorkflowSlice";
import {useStateDebounced} from "../../hooks/useStateDebounced";
import {getRequesterName, getReasonName, validateRequesterFilled, loadReasonList} from "../../utils";
import {Reason, ReasonListItem} from "../../typing";
import {AboutCancellationLoader} from "../loadings/Loadings";

type Props = {
  title: string;
};

export const AboutCancellation: React.FunctionComponent<Props> = ({title}) => {
  //Redux State
  const dispatch = useAppDispatch();
  const reservation = useSelector((state: RootState) => state.cancellationWorkflow?.reservation);
  const isAirbnbChannel = reservation?.data?.isAirbnbChannel;
  const aboutCancellationData = useSelector((state: RootState) => state.cancellationWorkflow.aboutCancellation);
  const currentPath = useSelector((state: RootState) => state.cancellationWorkflow.currentPathname);
  const fetchingData = useSelector(
    (state: RootState) =>
      state.cancellationWorkflow.reservation.action.fetching ||
      state.cancellationWorkflow.unit.action.fetching ||
      state.finance.adjustmentPreview.action.fetching ||
      state.finance.payment.action.fetching
  );

  //Local States
  const initialReasonList: ReasonListItem = {option: "Select Reason", id: null, id_requester: null, requiredNote: false};
  const [requestedBy, setRequestedBy] = useState<number>(aboutCancellationData?.requestedBy);
  const [reason, setReason] = useState<Reason>(aboutCancellationData?.reason);
  const [_, debouncedNotes, setInternalNotes] = useStateDebounced(aboutCancellationData.internalNotes, 500);
  const [Explanation, debouncedExplanation, setExplanationNotes] = useStateDebounced(aboutCancellationData.explanationNotes, 500);
  const [cancellationStatus, setCancellationStatus] = useState(aboutCancellationData.cancelOnAirbnb);

  const [reasonsList, setReasonsList] = useState<ReasonListItem[]>([initialReasonList]);
  const [requiredNote, setRequiredNote] = useState<boolean>(false);

  const handleCancellationChange = (status: boolean) => {
    setCancellationStatus(status);
  };

  useLayoutEffect(() => {
    if (aboutCancellationData?.requestedBy) {
      setReasonsList(loadReasonList(requestedBy));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequesterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const requesterSelected: string = event?.target?.value;
    setRequestedBy(Number(requesterSelected));

    //We filter by the requester selected and get the reasons list (this list is hardcoded in the staticData file)
    const reasonListFiltered = aboutCancellationStaticData.requesters.filter((requester) => requester.id === Number(requesterSelected))[0]?.reasons;
    setReasonsList(reasonListFiltered ? reasonListFiltered : [initialReasonList]);
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reason: string = event?.target?.value;
    const requiredNote = aboutCancellationStaticData.requesters
      ?.filter((requester) => requester.id === requestedBy)[0]
      .reasons.filter((_reason) => _reason?.id === Number(reason))[0]?.requiredNote;
    setRequiredNote(requiredNote);
    setReason({
      id: Number(reason),
      requiredNote,
    });
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>, setter: React.Dispatch<React.SetStateAction<string>>) => {
    const notes: string = event.target.value;
    setter(notes);
  };

  /**
   * When 'requested by'(requestedBy) changes, the value of requestedBy in aboutCancellationData is updated
   */
  useEffect(() => {
    if (requestedBy) {
      dispatch(
        setAboutCancellationData({
          ...aboutCancellationData,
          requestedBy,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedBy]);

  /**
   * When 'Cancellation Reason'(reason) changes, the value of reason in aboutCancellationData is updated
   */
  useEffect(() => {
    if (reason) {
      dispatch(
        setAboutCancellationData({
          ...aboutCancellationData,
          reason,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reason]);

  /**
   * When 'Internal cancellation notes'(debouncedNotes) changes, the value of debouncedNotes in aboutCancellationData is updated
   */
  useEffect(() => {
    if (debouncedNotes) {
      dispatch(
        setAboutCancellationData({
          ...aboutCancellationData,
          internalNotes: debouncedNotes,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedNotes]);

  /**
   * When 'Explanation to AirBnB'(explanationNotes) changes, the value of explanationNotes in aboutCancellationData is updated
   */
  useEffect(() => {
    if (debouncedExplanation) {
      dispatch(
        setAboutCancellationData({
          ...aboutCancellationData,
          explanationNotes: debouncedExplanation,
        })
      );
    }
  }, [debouncedExplanation]);

  /**
   * UseEffect to modify the value of if it was canceled by airbnb, the state of aboutCancellationData is
   * maintained and only cancelOnAirbnb is modified.
   * if No, return the states to the beginning
   */
  useEffect(() => {
    if (!cancellationStatus) {
      dispatch(
        setAboutCancellationData({
          ...aboutCancellationData,
          file: null,
          explanationNotes: "",
          cancelOnAirbnb: cancellationStatus,
        })
      );
    } else {
      dispatch(
        setAboutCancellationData({
          ...aboutCancellationData,
          cancelOnAirbnb: cancellationStatus,
        })
      );
    }
  }, [cancellationStatus]);

  /**
   * Validate the fields of the form that are correctly filled out.
   * For that, it calls the function validateRequesterFilled
   */
  useEffect(() => {
    const isRequesterInfoFilled = validateRequesterFilled(
      requestedBy,
      reason,
      debouncedNotes,
      isAirbnbChannel,
      cancellationStatus,
      debouncedExplanation
    );
    dispatch(setValidCancellation(isRequesterInfoFilled));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedBy, reason, debouncedNotes, cancellationStatus, debouncedExplanation]);

  return fetchingData ? (
    <AboutCancellationLoader />
  ) : (
    <div className={"columnFlexAbout"}>
      <h2 className={"boxTitle"}>{title}</h2>

      {!currentPath.includes("review") ? (
        <>
          <div className={styles.selectRequester}>
            <Select label={"Requested by"} options={aboutCancellationStaticData.requesters} onChange={handleRequesterChange} value={requestedBy} />
          </div>
          <Select label={"Cancellation Reason"} options={reasonsList} value={reason?.id} onChange={handleReasonChange} />
          <TextArea
            title={`Internal cancellation notes ${requiredNote ? "(required)" : "(optional)"}`}
            onChange={(event) => handleNotesChange(event, setInternalNotes)}
            rows={4}
            value={debouncedNotes}
          />
          {isAirbnbChannel && (
            <Checkbox
              question="Cancel on AirBnB?"
              info="If you select yes, the reservation is already canceled on AirBnB."
              options={[
                {text: "Yes", value: true},
                {text: "No", value: false},
              ]}
              value={cancellationStatus}
              onChange={handleCancellationChange}
            />
          )}
          {isAirbnbChannel && aboutCancellationData.cancelOnAirbnb === true && (
            <>
              <TextArea
                title={`Explanation cancellation notes (communicated to Airbnb) (required)`}
                onChange={(event) => handleNotesChange(event, setExplanationNotes)}
                rows={3}
                value={debouncedExplanation}
                info="This is the explanation that will be sent to Airbnb. It is mandatory to fill it in."
              />
              <WarningCancellationAirbnb />
            </>
          )}
        </>
      ) : (
        <>
          <span>Requested by</span>
          <p className={styles.responses}>{getRequesterName(requestedBy)}</p>

          <span>Cancellation Reason</span>
          <p className={styles.responses}>{getReasonName(requestedBy, aboutCancellationData?.reason?.id)}</p>

          <span>Internal cancellation notes</span>
          <p className={styles.responses}>{aboutCancellationData?.internalNotes === "" ? "--" : aboutCancellationData?.internalNotes}</p>

          {isAirbnbChannel && (
            <>
              <span>Cancel on AirBnB</span>
              <p className={styles.responses}>{aboutCancellationData?.cancelOnAirbnb ? "Yes" : "No"}</p>
            </>
          )}
          {isAirbnbChannel && aboutCancellationData?.cancelOnAirbnb === true && (
            <>
              <span>Explanation to AirBnB</span>
              <p className={styles.responses}>{aboutCancellationData?.explanationNotes === "" ? "--" : aboutCancellationData?.explanationNotes}</p>
            </>
          )}
        </>
      )}
    </div>
  );
};
