import {TRIP_PROTECTION_PROVIDERS, TRIP_PROTECTION_PROVIDERS_INFO} from "../../constants";
import {aboutCancellationStaticData} from "../../data/staticData";
import {ReasonListItem, TripProtectionProviderInfo} from "../../typing";

export const formattedCustomAmounts = (value: string) =>
    value
        .replace(/[^0-9.]/g, "")
        .replace(/\.{2,}/g, ".")
        .replace(/^0*([^0]\d*\.\d{1,2}).*/g, "$1");

export const toLocalString = (number: number, currency: string) => {
    return number.toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        style: "currency",
        currency,
    });
};

/**
 * Redirect to URL specified. If a time is specified, it will redirect after that time. (is in milliseconds)
 * @param {string} url
 * @param {number} [timeToRedirect=0]
 */
export const redirectToURL = (url: string, timeToRedirect: number = 0): void => {
    setTimeout(() => {
        window.top.location.href = url;
    }, timeToRedirect);
};

/**
 * Gets the requester's name of the cancellation (Vacasa, Owner, Guest)
 * @param {number} requesterId
 */
export const getRequesterName = (requesterId: number): string => {
    return aboutCancellationStaticData.requesters.filter((requester) => requester.id === requesterId)[0].option;
};

/**
 * Gets the reason name of the cancellation
 * @param {number} requesterId
 * @param {number} reasonId
 */
export const getReasonName = (requesterId: number, reasonId: number): string => {
    return aboutCancellationStaticData.requesters
        .filter((requester) => requester?.id === requesterId)[0]
        .reasons?.filter((reason) => reason?.id === reasonId)[0]?.option;
};

/**
 * Loads the reason list of the cancellation by the requester selected
 * @param {number} requesterId
 */
export const loadReasonList = (requesterId: number): ReasonListItem[] => {
    return aboutCancellationStaticData.requesters.filter((requester) => requester.id === requesterId)[0]?.reasons;
};

export const getTripProtectionProviderInfo = (provider: string): TripProtectionProviderInfo => {
    return TRIP_PROTECTION_PROVIDERS_INFO[provider];
};

export const getTripProtectionProviderName = (providerId: string): string => {
    return TRIP_PROTECTION_PROVIDERS[providerId];
};
